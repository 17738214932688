import React, { FC, useEffect, useState } from "react";
import Zoom from "@mui/material/Zoom";
import CloseIcon from "@mui/icons-material/Close";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { Box, ClickAwayListener, Tooltip, TooltipProps, Typography, IconButton } from "@mui/material";
import { trackEvent } from "@/lib/track";
import useLocalStorageValue from "@/hooks/useLocalStorageValue";

interface TutorialNewFeatureProps {
  identify: string;
  title: FC | React.ReactElement;
  body: FC | React.ReactElement;
  placement: TooltipProps["placement"];
  showNew?: boolean;
  /**
   * If the tutorial is active or not
   */
  active?: boolean;
}

const DELAY_INTRO = 1000;

export const TutorialFeature: FC<TutorialNewFeatureProps> = ({
  children,
  identify,
  title,
  body,
  placement,
  showNew = true,
  active = true,
}) => {
  const [value, setValue] = useLocalStorageValue(`tutorial-new-feature-${identify}-showed`, false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!value) {
      timer = setTimeout(() => {
        setVisible(true);
      }, DELAY_INTRO);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  if (value) {
    return <>{children}</>;
  }

  const handleClickCloseTooltip = () => {
    setVisible(false);
    setValue(true);
    trackEvent(`tutorial.${identify}.clickCloseTooltip`);
  };

  const handleClickOutside = () => {
    setVisible(false);
    trackEvent(`tutorial.${identify}.clickOutside`);
  };

  return (
    <Tooltip
      title={
        <ClickAwayListener onClickAway={handleClickOutside}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Box sx={{ padding: 1, width: "calc(100% - 20px)" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
                {showNew && <FiberNewIcon />}
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  flexWrap={"wrap"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                >
                  {title}
                </Typography>
              </Box>
              <Typography variant="body2" lineHeight={1.2}>
                {body}
              </Typography>
            </Box>

            <IconButton sx={{ color: "white" }} edge={"start"} onClick={handleClickCloseTooltip}>
              <CloseIcon fontSize="xsmall" />
            </IconButton>
          </Box>
        </ClickAwayListener>
      }
      TransitionComponent={Zoom}
      open={visible && active}
      placement={placement}
      arrow
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};
