import { dashboardAxios as axios } from "@/lib/axios";
import { FaqMenuItem } from "./faqsService";

export async function fetchFaqsMenuByLocale(
  locale: string,
  allowNotTranslated: boolean = true
): Promise<FaqMenuItem[]> {
  const menu = await axios.get<FaqMenuItem[]>(
    `/v1/chats/faqs?locale=${locale}&allowNotTranslated=${allowNotTranslated}`
  );
  return menu.data;
}

export async function fetchFaqsMenu(allowNotTranslated: boolean = true): Promise<FaqMenuItem[]> {
  const menu = await axios.get<FaqMenuItem[]>(`/v1/chats/faqs?allowNotTranslated=${allowNotTranslated}`);
  return menu.data;
}
