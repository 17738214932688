import { MetricDataSource } from "@/modules/board/models/metricDataSource";
import { joinReports } from "./joinReports";
import { Report } from "@/services/reportService";
import { transformReport } from "@/services/helpers/reportTransformer/transformReport";
import { EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";

export function mergeReport(
  accumulatedReport: Report,
  dataSource: MetricDataSource,
  report: Report,
  employeeFields: EmployeeFieldWithTranslation[]
): Report {
  const transformed = transformReport(dataSource.transform, report, employeeFields);

  if (!dataSource.join) {
    return {
      headers: { ...accumulatedReport.headers, ...transformed.headers },
      data: [...accumulatedReport.data, ...(transformed.data ?? [])],
      hasAnonymizedRows: accumulatedReport.hasAnonymizedRows || transformed.hasAnonymizedRows,
    };
  }

  return joinReports(accumulatedReport, transformed, dataSource.join);
}
