import { SPLIT_OFF, SPLIT_ON, SplitTreatment } from "@/hooks/useSplitTreatment";

export const FEATURE_FLAGS: Record<SplitTreatment, boolean> = {
  [SplitTreatment.EDIT_TEAMS]: true,
  [SplitTreatment.CREATE_TEAMS]: true,
  [SplitTreatment.CREATE_MANAGERS]: true,
  [SplitTreatment.MANAGERS_CAN_CREATE_TEAMS]: true,
  [SplitTreatment.FAQS_ACTIVATED]: true,
  [SplitTreatment.OFFBOARDING_CONTACT_ENABLED]: true,
  [SplitTreatment.AUTOMATIC_OFFBOARDING_DELIVERY_ACTIVATED]: false,
  [SplitTreatment.EXPERIENCE_MODULE_ACTIVATED]: true,
  [SplitTreatment.FEEDBACK_ENABLED]: true,
  [SplitTreatment.CAN_FILTER_BY_ONE_TIME_QUESTIONS]: true,
  [SplitTreatment.HIDE_SECTION_OF_HELPDESK_DASHBOARD]: false,
  [SplitTreatment.SHOW_BOARD_SHARE_BUTTON]: true,
  [SplitTreatment.FEEDBACK_AUTO_TAG]: true,
  [SplitTreatment.DEV_REV_PLUG_SESSION_ENABLED]: false,
  [SplitTreatment.IS_MOOD_QUESTION_ENABLED]: true,
  [SplitTreatment.PATTERN_RULES_SECTION_ENABLED]: true,
};

function mapToSplitTreatment(featureFlags: Record<SplitTreatment, boolean>): SplitIO.MockedFeaturesMap {
  return Object.entries(featureFlags).reduce<SplitIO.MockedFeaturesMap>((acc, [key, value]) => {
    acc[key] = value ? SPLIT_ON : SPLIT_OFF;
    return acc;
  }, {});
}

export const localSplitTreatments = mapToSplitTreatment(FEATURE_FLAGS);
