import {
  AdvanceAndOldFilterValues,
  mapAdvanceOrOldFiltersToParams,
} from "@/components/advanced-filters/utils/mapAdvanceOrOldFiltersToParams";
import { dashboardAxios as axios } from "@/lib/axios";
import { DatasetView } from "@/types/common";
import { ListResult } from "@alanszp/core";
import { camelCase, flatten } from "lodash";

export enum ViewForDataset {
  RUN = "run",
  QUERY = "query",
}

export async function getAllDatasets(
  viewFor?: ViewForDataset,
  page: number = 1,
  previousResults: DatasetView[] = []
): Promise<DatasetView[]> {
  const results = await getDatasets(page, 100, viewFor);

  const allResults = [...previousResults, ...results.elements];

  if (results.elements.length < results.pageSize) {
    return allResults;
  }

  return getAllDatasets(viewFor, page + 1, allResults);
}

export async function getDatasets(
  page: number = 1,
  pageSize: number = 100,
  viewFor?: ViewForDataset
): Promise<ListResult<DatasetView>> {
  const result = await axios.get<ListResult<DatasetView>>("v1/datasets", {
    params: {
      page,
      pageSize,
      viewFor,
    },
  });

  return result.data;
}

interface DatasetFieldValuesResponse {
  headers: {
    value: string;
  };
  data: { keys: ["value"]; values: string[][] };
}

export async function getDatasetFieldValues(
  datasetId: string,
  fieldName: string,
  filters?: AdvanceAndOldFilterValues
): Promise<string[]> {
  const params = mapAdvanceOrOldFiltersToParams(filters);
  const response = await axios.get<DatasetFieldValuesResponse | undefined>("/v3/reports/fieldValueDataset/compact", {
    params: {
      ...params,
      dataset: datasetId,
      field: camelCase(fieldName),
      translate: false,
    },
  });

  return flatten(response.data?.data?.values ?? []);
}
