import { ErrorBox } from "@/components/ErrorBox";
import { TooltipOnOverflow } from "@/components/TooltipOnOverflow";
import { Box } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { SxProps } from "@mui/system";

export const NoPermissionDisclaimer: FC<{ permissionsNotMet: string[]; sx?: SxProps }> = ({
  sx,
  permissionsNotMet,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
        p: 1,
        py: 2,
        ...sx,
      }}
    >
      <DoNotDisturbOnIcon fontSize="large" color={"error"} />
      <ErrorBox
        title={t("common.permissions.noPermission")}
        subtitle={t("common.permissions.noPermissionForAction")}
        sx={{ gap: 0 }}
      />
      <TooltipOnOverflow
        title={permissionsNotMet}
        boxProps={{
          fontFamily: "monospace",
          fontSize: 10,
          color: "text.secondary",
        }}
      >
        {permissionsNotMet}
      </TooltipOnOverflow>
    </Box>
  );
};
