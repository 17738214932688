import type { WidgetMetric } from "@/modules/board/models/board";
import { OrderDirection } from "@alanszp/core";

export enum MetricDataFilterOperator {
  IS_EMPTY = "empty",
  IS_NOT_EMPTY = "not_empty",
  IS_NULL = "null",
  IS_NOT_NULL = "not_null",
  EQ = "eq",
  NOT_EQ = "not_eq",
  IN = "in",
  NOT_IN = "not_in",
  GREATER_THAN = "gt",
  LOWER_THAN = "lt",
  GREATER_THAN_OR_EQUAL = "gte",
  LOWER_THAN_OR_EQUAL = "lte",
}

export type MetricDataFilterValueSimpleType = string | number | boolean;

export type MetricDataFilterValueType = MetricDataFilterValueSimpleType | MetricDataFilterValueSimpleType[];

export interface MetricDataFilterDefinition {
  /**
   * Field to filter the rows.
   */
  field: string;

  /**
   * Operator to compare the field with the value.
   */
  operator: MetricDataFilterOperator;

  /**
   * Value that the field will be compared to.
   */
  value?: MetricDataFilterValueType;
}

export enum MetricDataFilterLogicalOperator {
  AND = "and",
  OR = "or",
}

export type MetricDataFilter =
  | MetricDataFilterDefinition
  | {
      operator: MetricDataFilterLogicalOperator;

      /**
       * Filters that will apply with the Logical Operator
       * @minItems 2
       */
      filters: MetricDataFilter[];
    };
export interface MetricDataOrder {
  /**
   * Field to order the data.
   */
  key: string;

  /**
   * Direction of the order.
   */
  direction: "asc" | "desc" | "ASC" | "DESC";

  /**
   * If the field should be treated as a number.
   * @default true
   */
  asNumber?: boolean;

  /**
   * If present, will try to honor the order of the values in the array.
   * If "tenure" is passed, it will order by the dynamic field "tenure" that is calculated in the backend.
   */
  customOrder?: string[] | "tenure";

  /**
   * If the null values should be first.
   * @default false
   */
  nullsFirst?: boolean;
}

export type ReportFilterValue = string | number | boolean | string[] | number[];
export type MetricDataQueryFilter = Record<string, ReportFilterValue>;
export type MetricDataQueryOrderBy = Record<string, OrderDirection>;

export interface MetricDataQuery {
  metric: WidgetMetric;

  /**
   * Time segmentation for the date. Not implemented in all metrics.
   */
  timeSegmentation?: TimeSegmentation;

  /**
   * All filters valid for the metric
   */
  filters?: MetricDataQueryFilter;

  /**
   * Group by in the backend. Will anonymize the data if the users can't read individual responses.
   */
  groupBy?: string[];

  /**
   * Order by in the backend. It's not always possible so you may need to use transform.orderBy
   */
  orderBy?: MetricDataQueryOrderBy;

  /**
   * Like group by but without anonymizing the data.
   * @deprecated Use groupBy instead
   */
  breakdown?: string;

  /**
   * The fields defined here will override any other global filter, breakdown or groupBy.
   * It has the max priority when fetching the data.
   */
  statics?: {
    groupBy?: string[];

    filters?: MetricDataQueryFilter;

    /** @deprecated Use groupBy or extraGroupBy instead */
    breakdown?: string;

    /**
     * An extra Group by property, that will be attached to the end of the groupBy but will not be affected neither by the global selector, global.groupBy or query.statics.groupBy.
     * This can be useful if you want to group by a field that is not in the global groupBy, but you want to keep the global groupBy intact so all queries share the same.
     */
    extraGroupBy?: string[];
  };

  /**
   * If the metric should be benchmarked, rethink this when implementing multi-metric fetching
   * @default false
   */
  benchmark?: boolean;
}

export interface MetricDataFieldMapping {
  /**
   * The field that will be changed.
   */
  fromField: string;

  /**
   * The new field name.
   */
  toField: string;

  /**
   * If a field is not found in the object, this value will be used.
   * @default null
   */
  defaultIfNull?: string | number | null;

  /**
   * Can override the header name of the fromField.
   */
  overrideHeaderName?: string;
}

export interface MetricDataAddValueMapping {
  /**
   * The value that will be set on toField
   */
  value: string | number;

  /**
   * The new field header name.
   */
  headerName: string;

  /**
   * The new field name.
   */
  toField: string;
}

export type MetricDataMapping = MetricDataFieldMapping | MetricDataAddValueMapping;

export type MetricDataLimitWithOffset = {
  /**
   * The number of rows to return.
   */
  limit: number;

  /**
   * The number of rows to skip.
   */
  offset: number;
};

export type MetricDataLimit = MetricDataLimitWithOffset | number;

export interface TransformMetricData {
  /**
   * Change keys of the data after it was fetched.
   * It's the first transformation that is done.
   */
  mappings?: MetricDataMapping[];

  /**
   * If mapping was defined and this is TRUE this will omit fields that are not defined in the mappings
   * in the final object.
   * @default false
   */
  omitFieldsNotDefinedInMapper?: boolean;

  /**
   * Filter the data after fetching it.
   * It's the second thing that is done.
   */
  filter?: MetricDataFilter;

  /**
   * Order the data after fetching it.
   * It's the last transformation that is done.
   */
  orderBy?: MetricDataOrder[];

  /**
   * Limit the number of rows returned. Can also define offset.
   */
  limit?: MetricDataLimit;
}

export interface JoinCondition {
  /**
   * The field from the original (previous) data source.
   */
  originalKey: string;

  /**
   * The field from the joined data source.
   */
  currentKey: string;
}

export enum MultipleResolutionJoinDataSource {
  FIRST = "first",
  LAST = "last",
  SUM = "sum",
  AVERAGE = "average",
  MAX = "max",
  MIN = "min",
  COUNT = "count",
}

export interface FieldDefinitionJoinDataSource {
  /**
   * The field from the joined data source.
   */
  fromField: string;

  /**
   * The field that will be added to the original DataSource Report result.
   * If not defined, it will be the same as fromField.
   */
  toField?: string;

  /**
   * How to resolve multiple values.
   * If the join condition matches multiple rows, this will define how to resolve the value.
   * @default "first"
   */
  multipleResolution?: MultipleResolutionJoinDataSource;

  /**
   * If a field is not found in the joined data source, this value will be used.
   * @default null
   */
  defaultIfNull?: string | number | null;

  /**
   * If there is no match in the join condition, and the column is defined in the original data source,
   * this will define if the value should be kept or set to null.
   *
   * @default "keepOldValue"
   */
  ifNoMatchingRows?: "keepOldValue" | "null";

  /**
   * Override header name
   */
  overrideHeaderName?: string | null;
}

export enum JoinDataSourceType {
  LEFT = "left",
  INNER = "inner",
  OUTER = "outer",
}

export interface JoinDataSource {
  /**
   * Defines if it's an inner or left join. If left, any row from the previous data source will be kept.
   * If it's `left`, the fields that are not found in the joined data source will be null.
   * If it's `inner`, the original rows that don't match the join condition will be removed.
   * If it's `outer`, both the original rows and the join rows that don't match will be kept.
   * @default "left"
   */
  type?: JoinDataSourceType;

  /**
   * The conditions that will be used to join the data sources.
   * All conditions are composed with an AND operator.
   * @minItems 1
   */
  condition: JoinCondition[] | true;

  /**
   * The fields that will be added to the original DataSource Report result.
   * Each object represents a field that will be added to the matching object.
   * If given just a string it will be interpreted as a shorthand of:
   *  {
   *    fromField: "valueGiven",
   *    toField: "valueGiven",
   *    multipleResolution: "first"
   *  }
   * @minItems 1
   */
  fields: (string | FieldDefinitionJoinDataSource)[];
}

export interface MetricDataSource {
  /**
   * The query that will be executed to report API
   */
  query: MetricDataQuery;

  /**
   * If the data should be transformed before being displayed.
   */
  transform?: TransformMetricData;

  /**
   * If the data should be joined with another data source.
   * It will be joined with the previous data source.
   * If not defined, this data source will be concatenated with the previous one.
   */
  join?: JoinDataSource;
}

export interface MetricData {
  /**
   * The data sources that will be queried and joined.
   * We support fetching multiple data sources, so you can have multiple metrics in a single widget.
   *
   * We will fetch the data from each source and join them using the join definition of this source.
   * If join definition is not set, we will concatenate the data.
   *
   * @minItems 1
   */
  sources: MetricDataSource[];

  /**
   * This global properties will override every source properties.
   * It doesn't make sense to have a global groupBy, and then define a groupBy in a source.
   *
   * The groupBy selector will overriding `global.groupBy`.
   * The global filters will override `global.filters`.
   */
  globals?: Pick<MetricDataQuery, "filters" | "groupBy" | "breakdown">;

  /**
   * If the data should be transformed before being displayed.
   */
  transform?: TransformMetricData;

  /**
   * If true, will render the data after transformation.
   */
  debug?: boolean;
}
export enum TimeSegmentation {
  MONTH = "month",
}
