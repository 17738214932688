export enum PromptSectionCode {
  DOCUMENTS_NOT_FOUND = "documents_not_found",
  DOCUMENTS_NOT_RELATED = "documents_not_related",
}

export interface PromptSection {
  id: string;
  org?: string | null;
  content: string;
  code: PromptSectionCode;
  createdAt?: Date;
  updatedAt?: Date;
}
