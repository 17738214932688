import { TransformMetricData } from "@/modules/board/models/metricDataSource";
import { filterReport } from "@/services/helpers/reportTransformer/filterReport";
import { orderReport } from "./orderReport";
import { mapReport } from "./mapReport";
import { Report } from "@/services/reportService";
import { limitReport } from "@/services/helpers/reportTransformer/limitReport";
import { EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";

export function transformReport(
  transformDefinition: TransformMetricData | undefined,
  report: Report,
  employeeFields: EmployeeFieldWithTranslation[]
): Report {
  if (!transformDefinition) return report;

  const mappedReport = transformDefinition?.mappings
    ? mapReport(report, transformDefinition.mappings, transformDefinition.omitFieldsNotDefinedInMapper)
    : report;

  const filteredReport = transformDefinition?.filter
    ? filterReport(mappedReport, transformDefinition.filter)
    : mappedReport;

  const orderedReport = transformDefinition?.orderBy
    ? orderReport(filteredReport, transformDefinition.orderBy, employeeFields)
    : filteredReport;

  return transformDefinition?.limit ? limitReport(orderedReport, transformDefinition?.limit) : orderedReport;
}
