import MarkdownEditor from "@/components/MarkdownEditor";
import * as Yup from "yup";
import { createPrompt } from "@/services/clerkPromptsService";
import { PromptSectionCode } from "@/types/prompt";
import { useFormikWithTranslations } from "@/utils/formikConnector";
import { LoadingButton } from "@mui/lab";
import { Button, Box, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { SelectInput } from "@/components/SelectInput";
import { useQueryClient } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import { AxiosError } from "axios";

export interface PromptSectionCreationFormValues {
  content: string;
  code: PromptSectionCode;
}

export const PromptCreateContainer: FC = () => {
  const { t } = useTranslation();
  const { organizationReference } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const goBack = () => navigate("/settings/prompts");
  const queryClient = useQueryClient();

  const handleSave = async ({ content, code }: PromptSectionCreationFormValues) => {
    setLoading(true);

    if (!content || !code) {
      console.error("PromptSectionCreateContainer :: Content or code not found");
      setLoading(false);
      return;
    }

    await createPrompt({ code, content })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["prompts", organizationReference ?? "default"] });
        enqueueSnackbar(t("settings.prompts.create.success"));
        goBack();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) {
          enqueueSnackbar(t("settings.prompts.create.errors.promptExists"), { variant: "error" });
          return;
        }
        enqueueSnackbar(t("settings.prompts.create.errors.serverError"), { variant: "error" });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik<PromptSectionCreationFormValues>({
    initialValues: { content: "", code: PromptSectionCode.DOCUMENTS_NOT_FOUND },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      code: Yup.string().required(t("settings.prompts.create.errors.codeIsRequired")),
      content: Yup.string().required(t("settings.prompts.create.errors.contentIsRequired")),
    }),
    onSubmit: handleSave,
  });

  const formikField = useFormikWithTranslations(formik);

  const promptSectionOptions = Object.values(PromptSectionCode).map((context: PromptSectionCode) => ({
    /* i18next-extract-mark-context-next-line ["documents_not_found", "documents_not_related"] */
    label: t(`settings.prompts.section`, { context }),
    value: context,
  }));

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 2, borderRadius: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography color="textSecondary" variant="body1">
          {t("settings.prompts.create.fieldCode")}
        </Typography>
        <SelectInput
          name="code"
          value={formik.values.code}
          onChange={(e) => {
            formik.setFieldValue("code", e.target.value);
          }}
          options={promptSectionOptions}
        />
        <Typography color="textSecondary" variant="body1">
          {t("settings.prompts.create.fieldContent")}
        </Typography>
        <MarkdownEditor
          placeholder={t("settings.prompts.create.placeholderInstruction")}
          multiline
          fullWidth
          autoFocus
          {...formikField("content")}
        />
        <Box sx={{ mt: 3, display: "flex", gap: 1, justifyContent: "flex-end" }}>
          {loading ? (
            <LoadingButton loading variant="contained" size="large" sx={{ mt: 2, float: "right" }} />
          ) : (
            <Button
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              variant="contained"
              size="large"
              sx={{ mt: 2, float: "right" }}
              type="submit"
            >
              {t("common.actions.save")}
            </Button>
          )}

          <Button
            variant="outlined"
            disabled={loading}
            size="large"
            sx={{ mt: 2, mr: 1, float: "right" }}
            onClick={goBack}
          >
            {t("common.actions.cancel")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};
