import { Helmet } from "react-helmet-async";

/**
 * SecurityHeaders Component
 *
 * This component manages security headers for the application, implementing different
 * security policies based on the environment (development vs production).
 *
 * In development: Applies minimal security headers
 * In production: Implements a strict Content Security Policy (CSP) and additional security headers
 */
const SecurityHeaders = () => {
  // Check if we're in development environment
  const isDevelopment = process.env.NODE_ENV === "development";

  // Apply different security headers based on environment
  const securityHeaders = isDevelopment ? (
    <Helmet>
      {/* Minimal security headers for development */}
      <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
      <meta name="referrer" content="strict-origin-when-cross-origin" />
    </Helmet>
  ) : (
    <Helmet>
      {/* Configuración balanceada para producción */}
      <meta httpEquiv="Cross-Origin-Embedder-Policy" content="unsafe-none" />
      <meta httpEquiv="Cross-Origin-Resource-Policy" content="cross-origin" />

      {/* Content Security Policy - Production only
       * Defines allowed sources for:
       * - Scripts (self, specific domains)
       * - Styles (self, Google Fonts)
       * - Fonts (self, Google Fonts)
       * - Images (self, data URIs, https, blobs)
       * - Connections (API endpoints, WebSocket connections)
       * - Frames (self, specific domains)
       * - Media (self, blobs)
       * - Other security restrictions
       */}
      <meta
        httpEquiv="Content-Security-Policy"
        content={`
        default-src 'self';
        script-src 'self' 'unsafe-inline' 
          https://plug-platform.devrev.ai 
          https://*.devrev.ai
          https://app.devrev.ai
          https://accounts.google.com 
          https://www.googletagmanager.com
          https://*.google.com
          https://www.google-analytics.com
          https://*.analytics.google.com
          https://vercel.live
          https://cdn.jsdelivr.net
          https://*.jsdelivr.net;
        script-src-elem 'self' 'unsafe-inline'
          https://plug-platform.devrev.ai
          https://*.devrev.ai
          https://app.devrev.ai
          https://accounts.google.com 
          https://www.googletagmanager.com
          https://*.google.com
          https://www.google-analytics.com
          https://*.analytics.google.com
          https://vercel.live
          https://*.vercel.live
          https://cdn.jsdelivr.net
          https://*.jsdelivr.net;
        style-src 'self' 'unsafe-inline' 
          https://fonts.googleapis.com 
          https://accounts.google.com
          https://cdn.jsdelivr.net
          https://*.jsdelivr.net;
        style-src-elem 'self' 'unsafe-inline' 
          https://fonts.googleapis.com 
          https://accounts.google.com
          https://*.google.com
          https://cdn.jsdelivr.net
          https://*.jsdelivr.net;
        font-src 'self' https://fonts.gstatic.com data: 
          https://cdn.jsdelivr.net 
          https://*.jsdelivr.net;
        img-src 'self' data: https: blob: 
          https://www.google-analytics.com 
          https://*.analytics.google.com
          https://analytics.google.com;
        connect-src 'self' 
          https://*.meetlara.ai
          https://app.meetlara.ai 
          wss://*.meetlara.ai 
          https://*.meetlara.net 
          https://dashboard.meetlara.net
          https://dashboard.meetlara.ai
          https://plug-platform.devrev.ai 
          https://*.devrev.ai
          https://api.devrev.ai 
          https://*.sentry.io 
          https://events.split.io
          https://streaming.split.io
          https://auth.split.io 
          https://sdk.split.io 
          https://api.mixpanel.com
          https://api-js.mixpanel.com
          https://vercel.live
          https://*.vercel.live
          https://accounts.google.com
          https://*.google.com
          https://login.microsoftonline.com
          https://*.microsoftonline.com
          https://*.microsoft.com
          https://www.google-analytics.com
          https://*.analytics.google.com
          https://analytics.google.com;
        frame-src 'self' 
          https://plug-platform.devrev.ai
          https://*.devrev.ai
          https://app.devrev.ai
          https://accounts.google.com 
          https://*.meetlara.ai
          https://app.meetlara.ai 
          https://*.meetlara.net
          https://accounts.google.com
          https://*.google.com
          https://login.microsoftonline.com
          https://*.microsoftonline.com
          https://*.microsoft.com;
        media-src 'self' blob:;
        manifest-src 'self';
        worker-src 'self' blob:;
        form-action 'self' 
          https://*.meetlara.ai 
          https://app.meetlara.ai
          https://*.meetlara.net;
        object-src 'none';
        base-uri 'self';
      `}
      />

      {/* Additional Security Headers
       * - Cache Control: Prevents caching of sensitive data
       * - X-Content-Type-Options: Prevents MIME type sniffing
       * - X-Frame-Options: Prevents clickjacking
       * - Referrer Policy: Controls information in referrer header
       * - Permissions Policy: Restricts access to browser features
       * - Surrogate-Control: Additional cache control
       */}
      <meta httpEquiv="Cache-Control" content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta
        httpEquiv="Permissions-Policy"
        content="accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=()"
      />
      <meta httpEquiv="Surrogate-Control" content="no-store" />
    </Helmet>
  );

  return securityHeaders;
};

export default SecurityHeaders;
