import { FC } from "react";
import Page from "@/components/Page";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HelpdeskPageTestContainer } from "../components/HelpdeskPageTestContainer";

const HelpdeskPageTest: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/helpdesk/knowledge");
  };

  return (
    <Page
      track={{ pageName: "helpdeskTest" }}
      locales={{
        title: t("helpdesk.test.title"),
        headTitle: t("helpdesk.test.title"),
      }}
      hideTitle
    >
      <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2} mb={2}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box display="flex" alignItems="center" gap={0}>
            <IconButton onClick={goBack} sx={{ mr: 0, py: 0 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography color="textPrimary" variant="h5">
              {t("helpdesk.test.title")}
            </Typography>
          </Box>
          <Typography color="textSecondary" variant="body2">
            {t("helpdesk.test.subtitle")}
          </Typography>
        </Box>
      </Box>
      <HelpdeskPageTestContainer />
    </Page>
  );
};

export default HelpdeskPageTest;
