import { EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";
import { NoDataForPeriodError } from "@/modules/board/errors/NoDataForPeriodError";
import { NotEnoughAnswersError } from "@/modules/board/errors/NotEnoughAnswersError";
import { BarVisualization, LineVisualization, isBarVisualization } from "@/modules/board/models/board";
import { DateRangeEnumTypeConfig, EmployeeFieldType } from "@/services/employeeService";
import { Report } from "@/services/reportService";
import { groupBy, isNil, isNumber, isString, keys, map, reverse, sumBy, uniq } from "lodash";

/**
 * Symbol used to identify when a value is anonymized
 */
export const ANONYMIZED_SYMBOL: unique symbol = Symbol.for("Anonymized");
export type AnonymizedSymbol = typeof ANONYMIZED_SYMBOL;

export function isAnonymizedSymbol(something: unknown): something is symbol {
  return something === ANONYMIZED_SYMBOL;
}

/**
 * @throws {NoDataForPeriodError}
 * @throws {NotEnoughAnswersError}
 */
export function mapReportToSeries(
  response: Report,
  visualization: LineVisualization | BarVisualization,
  employeeFields: EmployeeFieldWithTranslation[]
): {
  series: {
    name: string;
    data: (number | null | AnonymizedSymbol)[];
  }[];
  categories: string[];
  singleDimension: boolean;
  rowsContextMap: Map<string, Record<string, string | number>>;
} {
  const reverseSeries = visualization.options.reverseSeries ?? true;
  const rows = reverseSeries ? reverse(response?.data ?? []) : response?.data ?? [];

  const categories = uniq(map(rows, (row) => row[visualization.options.xAxis.labelField] as string));

  const singleDimension = visualization.options.xAxis.labelField === visualization.options.xAxis.valueField;
  const needsLabels = visualization.options.yAxis.labelField !== visualization.options.yAxis.valueField;

  const isStackedBar = isBarVisualization(visualization) && visualization.options.stacked;

  const series = singleDimension
    ? [
        {
          name: visualization.options.yAxis.labelField ?? "-",
          data: mapRowsWithFixedCategoryOrder(categories, rows, visualization),
          labels: needsLabels ? map(rows, (row) => row[visualization.options.yAxis.labelField] as string) : undefined,
        },
      ]
    : // Multi-dimensional
      map(
        groupBy(rows, (row) => row[visualization.options.xAxis.valueField]),
        (data, name) => {
          return {
            name: name ?? "-",
            data: mapRowsWithFixedCategoryOrder(categories, data, visualization, isStackedBar),
          };
        }
      );

  // Generate rows context map
  // This is needed to get the context for each row when the user clicks on a data point
  const rowsContextMap = new Map<string, Record<string, string | number>>();

  if (singleDimension) {
    /**
     * If single dimension, we need to map the rows to the categories, so we can get the context for each category
     * @key category
     */
    rows.forEach((row) => {
      rowsContextMap.set(row[visualization.options.xAxis.labelField] as string, row);
    });
  } else {
    /**
     * If multi dimension, we need to map the rows to the series, so we can get the context for each series
     * @key {series.name}-{category}
     */
    series.forEach((s, i) => {
      const seriesRows = rows.filter((row) => row[visualization.options.xAxis.valueField] === s.name);
      seriesRows.forEach((row) => {
        const key = `${s.name}-${row[visualization.options.xAxis.labelField]}`;
        rowsContextMap.set(key, row);
      });
    });
  }

  // If is a stacked bar, we need to sort based on the stackOrder option
  if (isStackedBar) {
    series.sort((a, b) => {
      if (!visualization.options.stackOrder) return 0;

      const tenureField = employeeFields.find((f) => f.fieldCode === "tenure");

      const stackOrder: string[] =
        visualization.options.stackOrder === "tenure"
          ? tenureField
            ? generateOrderBasedOnTenure(tenureField)
            : []
          : visualization.options.stackOrder ?? [];

      const aIndex = stackOrder.indexOf(a.name);
      const bIndex = stackOrder.indexOf(b.name);
      return aIndex - bIndex;
    });
  }

  const areAllSeriesEmpty = series.length === 0 || series.every((series) => series.data.length === 0);
  if (areAllSeriesEmpty) throw new NoDataForPeriodError();

  const areAllSeriesValuesAnonymized = series.every((series) => series.data.every((value) => value === null));
  if (areAllSeriesValuesAnonymized) throw new NotEnoughAnswersError();

  return { series, categories, singleDimension, rowsContextMap };
}

export function mapRowsWithFixedCategoryOrder(
  categories: string[],
  rows: Record<string, string | number | undefined>[],
  visualization: BarVisualization | LineVisualization,
  forceMissingCategoryFillZero?: boolean
): (number | null | AnonymizedSymbol)[] {
  return map(categories, (cat) => {
    const matchingRows = rows.filter((r) => r[visualization.options.xAxis.labelField] === cat);
    if (!matchingRows || matchingRows.length === 0)
      return forceMissingCategoryFillZero || visualization.options.renderNullValuesAsZero ? 0 : null;

    return mapRowDataPoint(matchingRows, visualization);
  });
}

export function mapRowDataPoint(
  rows: Record<string, string | number | undefined>[],
  visualization: BarVisualization | LineVisualization
): number | null | AnonymizedSymbol {
  const values = rows.map((row) => {
    const value = row?.[visualization.options.yAxis.valueField];

    if (isNil(value)) {
      return visualization.options.renderNullValuesAsZero ? 0 : null;
    }

    // This should no be necessary anymore bc is filtered out, but leaving it here just in case
    if (isString(value) && isNaN(Number(value))) {
      return ANONYMIZED_SYMBOL;
    }
    return isNumber(value) ? value : Number(value);
  });

  if (values.length === 1) return values[0];

  if (values.includes(ANONYMIZED_SYMBOL)) return ANONYMIZED_SYMBOL;

  if (values.every((v) => v === null)) return null;

  return sumBy(values, (v) => (isNumber(v) ? v : 0));
}

export function generateOrderBasedOnTenure(tenureField: EmployeeFieldWithTranslation): string[] {
  const { typeConfig } = tenureField;
  if (tenureField.type !== EmployeeFieldType.DATE_RANGE_ENUM || !typeConfig || !("steps" in typeConfig)) return [];
  return (typeConfig as DateRangeEnumTypeConfig).steps.flatMap((step) =>
    keys(step.label).map((key) => step.label[key])
  );
}
