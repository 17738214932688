import {
  MetricDataAddValueMapping,
  MetricDataFieldMapping,
  MetricDataMapping,
} from "@/modules/board/models/metricDataSource";
import { Report } from "@/services/reportService";
import { fromPairs, isObject, isString, isNumber, compact } from "lodash";

function isMetricDataFieldMapping(value: unknown): value is MetricDataFieldMapping {
  const mapping = value as MetricDataFieldMapping;
  return isObject(mapping) && isString(mapping.fromField) && isString(mapping.toField);
}

function isMetricDataAddValueMapping(value: unknown): value is MetricDataAddValueMapping {
  const mapping = value as MetricDataAddValueMapping;
  return (
    isObject(mapping) &&
    isString(mapping.headerName) &&
    (isString(mapping.value) || isNumber(mapping.value)) &&
    isString(mapping.toField)
  );
}

export function mapReport(
  report: Report,
  mappings: MetricDataMapping[],
  omitFieldsNotDefinedInMapper?: boolean
): Report {
  const newHeaders = fromPairs(
    compact(
      mappings.map((mapping) => {
        if (isMetricDataFieldMapping(mapping)) {
          return [mapping.toField, mapping.overrideHeaderName ?? report.headers[mapping.fromField] ?? ""];
        }
        if (isMetricDataAddValueMapping(mapping)) {
          return [mapping.toField, mapping.headerName];
        }
        return null;
      })
    )
  );

  return {
    headers: omitFieldsNotDefinedInMapper ? newHeaders : { ...report.headers, ...newHeaders },
    data: report.data.map((row) => {
      const newRow = fromPairs(
        compact(
          mappings.map((mapping) => {
            if (isMetricDataFieldMapping(mapping)) {
              return [mapping.toField, row[mapping.fromField] ?? mapping.defaultIfNull ?? null];
            }
            if (isMetricDataAddValueMapping(mapping)) {
              return [mapping.toField, mapping.value];
            }
            return null;
          })
        )
      );
      return omitFieldsNotDefinedInMapper ? newRow : { ...row, ...newRow };
    }),
    hasAnonymizedRows: report.hasAnonymizedRows,
  };
}
