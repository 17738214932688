import { dashboardAxios as axios } from "@/lib/axios";
import { PromptSection } from "@/types/prompt";
import { ListResult } from "@alanszp/core";

export async function getPrompts(): Promise<ListResult<PromptSection>> {
  const response = await axios.get<ListResult<PromptSection>>(`/v1/prompts`);
  return response.data;
}

export async function createPrompt(data: { content?: string; code: string }): Promise<PromptSection[]> {
  const response = await axios.post<PromptSection[]>(`/v1/prompts`, data);
  return response.data;
}

export async function updatePrompt(id: string, data: { content?: string }): Promise<PromptSection[]> {
  const response = await axios.put<PromptSection[]>(`/v1/prompts/${id}`, data);
  return response.data;
}

export async function deletePrompt(id: string): Promise<void> {
  await axios.delete<PromptSection[]>(`/v1/prompts/${id}`);
}
