import { useCallback, useContext, useMemo } from "react";
import { DateRangeEnumTypeConfig, EmployeeField, EmployeeFieldType } from "@/services/employeeService";
import { EmployeeFieldsContext, RefetchOptions } from "@/contexts/EmployeeFieldsContext";
import { useCurrentLanguage } from "@/hooks/useCurrentLanguage";
import { camelCase } from "lodash";

export type EmployeeFieldTranslationGetter = (fieldCodeOrDbColumn: string, fallback: string) => string;

export interface EmployeeFieldWithTranslation extends EmployeeField {
  translation: string;
}

/**
 * Exposes fields global context
 * @returns Fields global context
 */
export function useEmployeeFields(): {
  fields: EmployeeFieldWithTranslation[];
  loading: boolean;
  error: undefined | unknown;
  findEmployeeField: (fieldCode: string) => EmployeeFieldWithTranslation | undefined;
  /**
   * @param fieldCode Code of the field, e.g. "firstName". Always use camelCase.
   * @param fallback In case the field is not found, this will be returned.
   * @returns Translated field name or fallback
   */
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter;
  reloadEmployeeFields: (o?: RefetchOptions) => void;
} {
  const locale = useCurrentLanguage();
  const { fields, loading, error, refetch } = useContext(EmployeeFieldsContext);

  const computedFields = useMemo(() => {
    return fields.map((field) => ({
      ...field,
      translation: field.translations[locale],
    }));
  }, [fields, locale]);

  const indexedByCode: Map<string, EmployeeFieldWithTranslation> = useMemo(() => {
    return computedFields.reduce((acc, field) => {
      acc.set(field.fieldCode, field);
      return acc;
    }, new Map());
  }, [computedFields]);

  const findEmployeeField = useCallback(
    (fieldCode: string) => indexedByCode.get(camelCase(fieldCode)),
    [indexedByCode]
  );

  const getEmployeeFieldTranslation = (fieldCodeOrDbColumn: string, fallback: string) =>
    findEmployeeField(fieldCodeOrDbColumn)?.translation ??
    computedFields.find((field) => field.dbColumn === fieldCodeOrDbColumn)?.translation ??
    fallback;

  return {
    fields: computedFields,
    loading,
    error,
    findEmployeeField,
    getEmployeeFieldTranslation,
    reloadEmployeeFields: (o = {}) => refetch(o),
  };
}

export function tenureStepLabel(
  tenureEmployeeField: EmployeeFieldWithTranslation,
  tenure: string,
  locale: string
): string {
  if (tenureEmployeeField.type !== EmployeeFieldType.DATE_RANGE_ENUM) return "";
  return (tenureEmployeeField.typeConfig as DateRangeEnumTypeConfig).steps.find((step) => step.name === tenure)?.label[
    locale
  ];
}
