import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

interface ProfileItemInfoProps<T> {
  fontSize: number;
  translation?: string;
  value: T;
  Icon?: any;
  action?: (value: T) => string;
}

const ValueComponent = <T,>({
  value,
  action,
  children,
}: {
  value: T;
  action?: (value: T) => string;
  children: React.ReactNode;
}): JSX.Element => {
  if (!action) return <>{children}</>;
  return (
    <Link href={action(value)} target="_blank" underline="hover" color="text.primary">
      {children}
    </Link>
  );
};

export const ProfileItemInfo = <T,>({ fontSize, translation, value, Icon, action }: ProfileItemInfoProps<T>) => {
  return (
    <Box alignItems="center" sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      {Icon && <Icon color="primary" sx={{ fontSize }} />}
      {translation ? (
        <Typography
          color="text.secondary"
          variant="body2"
          display="inline"
          fontSize={fontSize}
          style={{ marginLeft: 2 }}
        >
          {`${translation}: `}
        </Typography>
      ) : null}
      <Typography
        variant="body2"
        display="inline"
        fontWeight="fontWeightMedium"
        fontSize={fontSize}
        style={{ marginLeft: 4 }}
      >
        <ValueComponent value={value} action={action}>
          {value}
        </ValueComponent>
      </Typography>
    </Box>
  );
};
