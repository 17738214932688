import { NoPermissionDisclaimer } from "@/components/NoPermissionsDisclaimer";
import { isLocal } from "@/config";
import { getLidFromError } from "@/helpers/getLidFromError";
import { isAxiosError } from "@/lib/axios";
import { Box, Button, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { isString } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface ErrorBoxProps {
  width?: number | string;
  height?: number | string;
  error?: unknown;
  title?: string;
  subtitle?: string;
  sx?: SxProps;
  icon?: boolean;
  onRetry?: () => unknown;
}

export const errorToString = (error: unknown): string | undefined =>
  isAxiosError(error)
    ? error.response?.data?.code
    : isString(error)
      ? error
      : error instanceof Error
        ? error.name + ": " + error.message
        : undefined;

export const ErrorBox: FC<ErrorBoxProps> = ({
  width = "100%",
  height = "100%",
  sx,
  onRetry,
  error,
  icon = false,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();

  const isNoPermissionError =
    isAxiosError(error) && error.response?.status === 403 && error.response?.data?.code === "no_permission_error";

  if (isNoPermissionError) {
    return (
      <NoPermissionDisclaimer
        permissionsNotMet={(error.response?.data?.context?.permissionsNotMet ?? []) as string[]}
        sx={sx}
      />
    );
  }

  const errorMessage = errorToString(error);

  const lid = getLidFromError(error);

  return (
    <Box
      width={width}
      height={height}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
        ...sx,
      }}
    >
      {icon && <img alt="error" src={"/static/error/undraw_qa_engineers.svg"} width={300} />}
      <Typography variant="body1">{title || t("components.errorBox.thereWasAnError")}</Typography>
      {subtitle ? <Typography variant="caption">{subtitle}</Typography> : null}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {errorMessage ? (
          <Typography variant="caption">{t("components.errorBox.errorCode", { error: errorMessage })}</Typography>
        ) : null}
        {isLocal() && error instanceof Error ? (
          <Typography variant="caption" color="text.secondary" fontSize={10}>
            {error.message}
          </Typography>
        ) : null}
        {lid ? (
          <Typography variant="caption" color="text.secondary" fontSize={10}>
            Error ID: {lid}
          </Typography>
        ) : null}
      </Box>
      {onRetry ? (
        <Button variant="text" onClick={onRetry}>
          {t("components.errorBox.retry")}
        </Button>
      ) : null}
    </Box>
  );
};
