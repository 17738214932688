import { ListResult } from "@alanszp/core";
import { isEmpty, omitBy } from "lodash";
import { Translation, TranslationLocales } from "@/types/common";
import { dashboardAxios } from "@/lib/axios";

export const TRANSLATION_LOCALES = ["es", "es-AR", "en", "pt"];
export const DEFAULT_NAMESPACE = "default";
export const DEFAULT_LOCALE = "es";

export interface SearchTranslationsParams {
  organization: string;
  locale: string;
  state?: "published" | "draft";
  key?: string;
  value?: string;
}

export async function searchTranslations(
  params: SearchTranslationsParams,
  pageSize?: number,
  page?: number
): Promise<ListResult<Translation>> {
  const response = await dashboardAxios.get<ListResult<Translation>>(`/v1/translations`, {
    params: {
      ...omitBy(params, isEmpty),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize } : {}),
    },
  });
  return response.data;
}

export interface CreateTranslationBody {
  locale: string;
  key: string;
  value: string;
  organization: string;
}

export type CreateBulkTranslationBody = Omit<CreateTranslationBody, "organization">[];

export async function createTranslation(body: CreateTranslationBody): Promise<Translation> {
  const response = await dashboardAxios.post<Translation>(`/v1/translations`, body);

  return response.data;
}

export type TranslationView = {
  translation: Record<string, unknown>;
  error?: string;
  context?: Record<string, unknown>;
};

export interface CreateBulkTranslationsResponse {
  success: TranslationView[];
  failed: TranslationView[];
}

export async function createBulkTranslation(
  organization: string,
  bulk: CreateBulkTranslationBody,
  updateOnExists: boolean = false
): Promise<CreateBulkTranslationsResponse> {
  const body = {
    organization,
    translations: bulk,
    updateOnExists,
  };
  const response = await dashboardAxios.post(`/v1/translations/bulk`, body);

  return response.data;
}

interface ModifyTranslationBody {
  value: string;
}

export async function modifyTranslation(translationId, body: ModifyTranslationBody): Promise<Translation> {
  const response = await dashboardAxios.patch<Translation>(`/v1/translations/${translationId}`, body);
  return response.data;
}

enum ValidationWarningType {
  NOT_PUBLISHED_IN_OTHER_LOCALES = "notPublishedInOtherLocales",
}

type PublishLocaleResponse = {
  type: ValidationWarningType;
  detail: { error: "notFoundButIsChild" | "needPublication"; key: string; locale: string }[];
};

export async function publishLocales(
  organization: string,
  locales: string[],
  force?: boolean
): Promise<PublishLocaleResponse[]> {
  const body: { organization: string; locales: string[]; force?: boolean } = { locales, organization };

  if (force) {
    body.force = force;
  }

  const response = await dashboardAxios.post(`/v1/translations/publish`, body);

  return response.data || [];
}

export async function getLocales(): Promise<TranslationLocales[]> {
  const response = await dashboardAxios.get<{ locales: TranslationLocales[] }>("/v1/translations/locales");
  return response.data.locales;
}

export async function deepLTranslate(from: TranslationLocales, to: TranslationLocales, text: string): Promise<string> {
  const response = await dashboardAxios.post<{ text: string }>(`/v1/translate`, {
    from,
    to,
    text,
  });
  return response.data.text;
}
