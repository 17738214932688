import { EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";
import { generateOrderBasedOnTenure } from "@/modules/board/hooks/widgetData/reportVisualizationMapper/helpers";
import { MetricDataOrder } from "@/modules/board/models/metricDataSource";
import { Report } from "@/services/reportService";
import { orderBy } from "lodash";

export function generateOrderByFn(
  orderObject: MetricDataOrder,
  tenureField?: EmployeeFieldWithTranslation
): (object: Record<string, string | number>) => string | number {
  return function orderByFn(object) {
    const { key, asNumber, nullsFirst, customOrder } = orderObject;
    let value = object.hasOwnProperty(key) ? object[key] : null;

    if (value === null) {
      if (nullsFirst) {
        return -Infinity;
      } else {
        return Infinity;
      }
    }

    if (customOrder) {
      if (customOrder === "tenure" && tenureField) {
        return generateOrderBasedOnTenure(tenureField).indexOf(value.toString());
      }
      const customOrderIndex = customOrder.indexOf(value.toString());
      return customOrderIndex === -1 ? Infinity : customOrderIndex;
    }

    if ((asNumber ?? true) && value !== "") {
      const numericValue = Number(value);
      if (isFinite(numericValue)) {
        value = numericValue;
      }
    }

    return value;
  };
}

export function orderReport(
  report: Report,
  orderByDefinition: MetricDataOrder[],
  employeeFields: EmployeeFieldWithTranslation[]
): Report {
  const tenureField = employeeFields.find((field) => field.fieldCode === "tenure");

  return {
    headers: report.headers,
    data: orderBy(
      report.data,
      orderByDefinition.map((order) => generateOrderByFn(order, tenureField)),
      orderByDefinition.map((order) => order.direction.toLowerCase() as "asc" | "desc")
    ),
    hasAnonymizedRows: report.hasAnonymizedRows,
  };
}
