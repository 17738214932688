import { groupBy, intersection, keys, maxBy, orderBy, sumBy } from "lodash";
import { ReportType, fetchReport, Locale } from "./reportService";
import { fetchFaqsMenu, fetchFaqsMenuByLocale } from "./faqsMenuService";
import { dashboardAxios } from "@/lib/axios";

import {
  BasicHelpdeskSection,
  BasicKnowledgeDocument,
} from "@/modules/helpdesk/components/CreateKnowledgeDocumentEditor";
import { TranslationLocales } from "@/types/common";
import { searchTranslations } from "./translationService";

export enum FaqMenuItemType {
  SUB_MENU = "sub_menu",
  FAQS = "faqs",
  FEEDBACK_TO_LARA = "feedback_to_lara",
  HOW_LARA_WORKS = "how_lara_works",
  WHEN_TALK_AGAIN = "when_talk_again",
  FEEDBACK_FOR_ORG_ANONYMOUS = "feedback_for_org_anonymous",
  FEEDBACK_FOR_ORG = "feedback_for_org",
  RECOGNITION = "recognition",
}

export enum FaqMenuItemFilterOperator {
  EQUALS = "equals",
  NOT_EQUALS = "not_equals",
}

export type FaqFilters = Record<
  string,
  { operator: FaqMenuItemFilterOperator; value: boolean | string | string[] | boolean[] }
>;

export interface FaqMenuItem {
  id: string;
  emoji?: string;
  description: string;
  children?: FaqMenuItem[] | null;
  type: FaqMenuItemType;
  response?: string;
  filters?: FaqFilters;
  intents?: string[];
  actionId?: string;
}

export interface HelpdeskMenuBaseItem {
  id: string;
  description: string;
  response?: string;
  children?: HelpdeskMenuBaseItem[];
  type: FaqMenuItemType.FAQS | FaqMenuItemType.SUB_MENU;
  filters?: FaqFilters;
  archived?: boolean;
}

export interface HelpdeskMenuStructureItem {
  id: string;
  type: FaqMenuItemType.FAQS | FaqMenuItemType.SUB_MENU;
  intents: [];
  organizationReference: string;
  children?: HelpdeskMenuStructureItem[];
}

export interface HelpdeskMenuItemTranslation {
  locale: TranslationLocales;
  key: string;
  value: string;
}

export interface FaqMenuItemWithTotals extends FaqMenuItem {
  totalUsage: number;
  totalUseful: number;
  totalNotUseful: number;
  children?: FaqMenuItemWithTotals[];
}

export interface FAQItemValue {
  id: string;
  name: string;
  value: number;
  percent: number;
}

export interface FAQItemUsefulValue {
  id: string;
  name: string;
  totalUseful: number;
  totalNotUseful: number;
}

export interface TotalFAQUsageDate {
  date: string;
  value: number;
}

interface FAQsAnalyticsValues {
  tree: FaqMenuItemWithTotals[];
  moreQueried: FAQItemValue[];
  lessQueried: FAQItemValue[];
  moreUseful: FAQItemUsefulValue[];
  lessUseful: FAQItemUsefulValue[];
  maxQueryValue: number;
}

interface QueriedValue {
  id: string;
  date: string;
  total: number;
  name?: string;
}

interface UsefulValues {
  id: string;
  date: string;
  useful: number;
  notUseful: number;
  name?: string;
}

function addValuesItemRecursively(
  item: FaqMenuItem,
  usageValues: QueriedValue[],
  usefulValues: UsefulValues[]
): FaqMenuItemWithTotals {
  let totalUsage = 0;
  let totalUseful = 0;
  let totalNotUseful = 0;

  if (item.type === FaqMenuItemType.FAQS) {
    usageValues.forEach((uv) => {
      if (uv.id === item.id) {
        totalUsage += uv.total;
      }
    });

    usefulValues.forEach((uv) => {
      if (uv.id === item.id) {
        totalUseful += uv.useful;
        totalNotUseful += uv.notUseful;
      }
    });
  } else if (item.type === FaqMenuItemType.SUB_MENU) {
    if (item?.children && item.children.length > 0) {
      item.children = item.children.map((it) => addValuesItemRecursively(it, usageValues, usefulValues));
      const childrenWithTotals = item.children as FaqMenuItemWithTotals[];
      totalUsage = childrenWithTotals.reduce((acc, current) => acc + current.totalUsage, 0);
      totalUseful = childrenWithTotals.reduce((acc, current) => acc + current.totalUseful, 0);
      totalNotUseful = childrenWithTotals.reduce((acc, current) => acc + current.totalNotUseful, 0);
    }
  }

  return {
    ...item,
    children: item.children as FaqMenuItemWithTotals[],
    totalUsage,
    totalUseful,
    totalNotUseful,
  };
}

function groupQueriedAndSum(usage: QueriedValue[]): FAQItemValue[] {
  const groupById = groupBy(usage, "id");

  const sumById = keys(groupById).map((id) => ({
    id: groupById[id][0].id,
    name: groupById[id][0].name,
    total: sumBy(groupById[id], "total"),
  }));

  const total = sumBy(sumById, "total");

  return sumById.map((s) => ({
    id: s.id,
    name: s.name || "-",
    value: s.total,
    percent: (s.total * 100) / total,
  }));
}

function groupUsefulAndSum(usage: UsefulValues[]): FAQItemUsefulValue[] {
  const groupById = groupBy(usage, "id");

  const sumById = keys(groupById).map((id) => ({
    id: groupById[id][0].id,
    name: groupById[id][0].name,
    useful: sumBy(groupById[id], "useful"),
    notUseful: sumBy(groupById[id], "notUseful"),
  }));

  return sumById.map((s) => ({
    id: s.id,
    name: s.name || "-",
    totalUseful: s.useful,
    totalNotUseful: s.notUseful,
  }));
}

const LIMIT_RANKING = 5;

function limitRanking<T>(items: T[]): T[] {
  return items.slice(0, LIMIT_RANKING);
}

interface FaqReportValueBase {
  category: string;
  date: string;
  id: string;
  subCategory: string;
}

interface FaqReportValueQueried extends FaqReportValueBase {
  total: number;
}

interface FaqReportValueUseful extends FaqReportValueBase {
  useful: number;
  notUseful: number;
}

interface TotalFaqsQueries {
  value: number;
}

interface TotalFaqsQueriesHistory extends TotalFaqsQueries {
  date: string;
}

function mapFaqReportValueValue<T extends { category: string; subCategory: string }>(item: T): T {
  const name = item.subCategory !== "" ? item.subCategory : item.category;

  return {
    ...item,
    name,
  };
}

async function fetchFaqsByMenu(locale: string, params: URLSearchParams): Promise<FaqReportValueQueried[]> {
  const fetchQueried = await fetchReport(ReportType.FAQS_BY_MENU, { filters: params, locale: locale as Locale });

  return ((fetchQueried.data as unknown as FaqReportValueQueried[]) || [])
    .reverse()
    .map((item) => mapFaqReportValueValue(item));
}

async function fetchFaqsUseful(locale: string, params: URLSearchParams): Promise<FaqReportValueUseful[]> {
  const fetchUseful = await fetchReport(ReportType.FAQS_USEFUL, { filters: params, locale: locale as Locale });
  return ((fetchUseful.data as unknown as FaqReportValueUseful[]) || []).map((item) => mapFaqReportValueValue(item));
}

export async function fetchTotalFaqs(locale: string, params: URLSearchParams): Promise<number> {
  const filters = new URLSearchParams(params);
  filters.set("withId", "true");
  filters.set("aggByDate", "false");

  const fetchTotalQueries = await fetchReport(ReportType.TOTAL_FAQS_QUERIES, { filters, locale: locale as Locale });

  return (fetchTotalQueries.data as unknown as TotalFaqsQueries[])[0]?.value || 0;
}

export async function fetchTotalFaqsHistory(locale: string, params: URLSearchParams): Promise<TotalFAQUsageDate[]> {
  const filters = new URLSearchParams(params);
  filters.set("withId", "true");
  filters.set("aggByDate", "true");

  const fetchHistory = await fetchReport(ReportType.TOTAL_FAQS_QUERIES, { filters, locale: locale as Locale });

  return fetchHistory.data as unknown as TotalFaqsQueriesHistory[];
}

export async function fetchFaqsAnalytics(locale: string, params: URLSearchParams): Promise<FAQsAnalyticsValues> {
  const menu = await fetchFaqsMenuByLocale(locale);

  params.set("withId", "true");

  const queriedServiceValues = await fetchFaqsByMenu(locale, params);

  const usefulServiceValues = await fetchFaqsUseful(locale, params);

  const queriedByNameAndTotal = groupQueriedAndSum(queriedServiceValues);

  const queriedByNameAndUseful = groupUsefulAndSum(usefulServiceValues);

  const moreQueried = limitRanking(orderBy(queriedByNameAndTotal, "value", ["desc"]));

  const lessQueried = limitRanking(orderBy(queriedByNameAndTotal, "value", ["asc"]));

  const moreUseful = limitRanking(orderBy(queriedByNameAndUseful, "totalUseful", ["desc"]));

  const lessUseful = limitRanking(orderBy(queriedByNameAndUseful, "totalNotUseful", ["desc"]));

  const tree = menu.map((item) => addValuesItemRecursively(item, queriedServiceValues, usefulServiceValues));

  const maxQueryValue = maxBy(queriedByNameAndTotal, "value")?.value || 0;

  return {
    moreQueried,
    lessQueried,
    moreUseful,
    lessUseful,
    tree,
    maxQueryValue,
  };
}

export interface KnowledgeDocument {
  id: string;
  title: string;
  body: string;
  path: string | null;
  filters?: FaqFilters;
}

function asArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

function mergeFilters(filters: FaqFilters, previousFilters: FaqFilters): FaqFilters {
  // should sum the filters. if the key collides, we should add the values
  const mergedFilters = { ...previousFilters };
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (!(key in acc)) {
      acc[key] = value;
    } else if (acc[key].operator !== value.operator) {
      acc[key] = {
        operator: FaqMenuItemFilterOperator.EQUALS,
        value: acc[key].operator === FaqMenuItemFilterOperator.EQUALS ? acc[key].value : value.value,
      };
    } else if (acc[key].operator === FaqMenuItemFilterOperator.EQUALS) {
      const matchingValues = intersection(asArray(acc[key].value), asArray(value.value));

      if (matchingValues.length > 0) {
        acc[key] = {
          operator: FaqMenuItemFilterOperator.EQUALS,
          value: matchingValues as string[],
        };
      } else {
        acc[key] = {
          operator: FaqMenuItemFilterOperator.EQUALS,
          value: ["-1"],
        };
      }
    } else if (acc[key].operator === FaqMenuItemFilterOperator.NOT_EQUALS) {
      acc[key] = {
        operator: FaqMenuItemFilterOperator.NOT_EQUALS,
        value: [...(asArray(acc[key].value) as string[]), ...(asArray(value.value) as string[])],
      };
    } else {
      acc[key] = {
        operator: FaqMenuItemFilterOperator.EQUALS,
        value: ["-1"],
      };
    }
    return acc;
  }, mergedFilters);
}

function mapMenuItemToKnowledgeDocuments(
  menu: FaqMenuItem,
  previousPath: string[],
  previousFilter: FaqFilters
): KnowledgeDocument[] {
  const mergedFilters = mergeFilters(menu.filters || {}, previousFilter);
  if (menu.type === FaqMenuItemType.FAQS) {
    return [
      {
        id: menu.id,
        title: menu.description,
        body: menu.response ?? "",
        path: previousPath.length > 0 ? previousPath.join(" > ") || "" : "",
        filters: mergedFilters,
      },
    ];
  }

  const path = [...previousPath, menu.description];

  return (menu.children || []).flatMap((child) => mapMenuItemToKnowledgeDocuments(child, path, mergedFilters));
}

export async function fetchAllKnowledgeDocuments(
  locale: string,
  organizationReference: string
): Promise<KnowledgeDocument[]> {
  const menu = await fetchFaqsMenuByLocale(locale);

  // Get all translations for the menu
  const translations = await Promise.all(
    menu.flatMap((item) => {
      const getTranslationsForItem = async (menuItem: FaqMenuItem): Promise<FaqMenuItem> => {
        const type = menuItem.type;
        if (!menuItem.description && (type === FaqMenuItemType.FAQS || type === FaqMenuItemType.SUB_MENU)) {
          const result = await searchTranslations({
            locale,
            organization: organizationReference,
            key: `common.menu.options.${type}.${menuItem.id}`,
          });
          const description = result.elements.find((t) => t.key.endsWith(".description"))?.value;
          const response =
            type === FaqMenuItemType.FAQS ? result.elements.find((t) => t.key.endsWith(".response"))?.value : undefined;

          return {
            ...menuItem,
            description: description || menuItem.description,
            ...(response && { response }),
          };
        }
        return menuItem;
      };

      const processItem = async (item: FaqMenuItem): Promise<FaqMenuItem> => {
        const processedItem = await getTranslationsForItem(item);
        if (processedItem.children) {
          const processedChildren = await Promise.all(processedItem.children.map(processItem));
          return { ...processedItem, children: processedChildren };
        }
        return processedItem;
      };

      return processItem(item);
    })
  );

  // Now that we have all items with their translations, we can map them
  return translations.flatMap((item) => mapMenuItemToKnowledgeDocuments(item, [], {}));
}

export async function createKnowledgeDocuments(
  translations: BasicKnowledgeDocument[],
  parentId: string | null
): Promise<string[] | null> {
  return await dashboardAxios.post(`/v1/chats/helpdesk/items/bulk`, { translations, parentId });
}

export async function fetchHelpdeskMenu(
  locale: string,
  organizationReference: string
): Promise<HelpdeskMenuBaseItem[]> {
  const menu = await fetchFaqsMenuByLocale(locale);

  // Procesar el árbol de manera recursiva
  const processMenuTree = async (items: FaqMenuItem[]): Promise<HelpdeskMenuBaseItem[]> => {
    // Filter items that are not FAQS or SUB_MENU
    const validItems = items.filter(
      (item) => item.type === FaqMenuItemType.FAQS || item.type === FaqMenuItemType.SUB_MENU
    );

    return Promise.all(
      validItems.map(async (item) => {
        // Procesar el item actual
        const processedItem: HelpdeskMenuBaseItem = {
          id: item.id,
          type: item.type as FaqMenuItemType.SUB_MENU | FaqMenuItemType.FAQS,
          description: item.description,
          response: item.response,
          filters: item.filters,
          archived: !!item.filters?.archived,
        };

        // Si no tiene descripción, buscar las traducciones
        if (!item.description && (item.type === FaqMenuItemType.FAQS || item.type === FaqMenuItemType.SUB_MENU)) {
          const result = await searchTranslations({
            locale,
            organization: organizationReference,
            key: `common.menu.options.${item.type}.${item.id}`,
          });

          processedItem.description =
            result.elements.find((t) => t.key.endsWith(".description"))?.value || item.description;

          if (item.type === FaqMenuItemType.FAQS) {
            processedItem.response = result.elements.find((t) => t.key.endsWith(".response"))?.value || item.response;
          }
        }

        // Procesar recursivamente los hijos si existen
        if (item.children && item.children.length > 0) {
          processedItem.children = await processMenuTree(item.children);
        }

        return processedItem;
      })
    );
  };

  return await processMenuTree(menu);
}

export async function fetchKnowledgeDocuments(
  locale: string,
  allowNotTranslated: boolean = true
): Promise<FaqMenuItem[]> {
  return await fetchFaqsMenuByLocale(locale, allowNotTranslated);
}

export async function createKnowledgeDocument(
  translations: BasicKnowledgeDocument,
  parentId: string | null
): Promise<string | null> {
  return await dashboardAxios.post(`/v1/chats/helpdesk/items`, { translations, parentId });
}

export async function createHelpdeskSection(
  translations: BasicHelpdeskSection,
  parentId: string | null
): Promise<string | null> {
  return await dashboardAxios.post(`/v1/chats/helpdesk/sections`, { translations, parentId });
}

export async function removeKnowledgeDocument(id: string): Promise<void> {
  return await dashboardAxios.delete(`/v1/chats/helpdesk/items/${id}`);
}

export type RelevantNode = {
  itemId: string;
  score: number;
};

export interface KnowledgeSearchResponse {
  topNodes: RelevantNode[];
  filteredNodes: RelevantNode[];
}

export interface HelpdeskKnowledgeSearchQueryParams {
  locale: string;
  message: string;
  employeeReference?: string;
  maxResults?: number;
}

export async function searchKnowledgeBase(
  params: HelpdeskKnowledgeSearchQueryParams
): Promise<KnowledgeSearchResponse> {
  const response = await dashboardAxios.get<KnowledgeSearchResponse>("v1/helpdesk/knowledge", {
    params: {
      ...params,
    },
  });

  return response.data;
}

function parseFilters(filters: FaqFilters): Record<string, { operator: FaqMenuItemFilterOperator; value: any[] }> {
  return Object.entries(filters).reduce<Record<string, { operator: FaqMenuItemFilterOperator; value: any[] }>>(
    (acc, [key, value]) => {
      acc[key] = {
        operator: value.operator,
        value: asArray(value.value),
      };
      return acc;
    },
    {}
  );
}

function cleanSubMenu(menu: FaqMenuItem, organizationReference: string, archived?: boolean): RootItem {
  const filters = extractValidFilters(menu.filters || {});

  const childrenConfig = getChildrenConfig(menu, organizationReference);

  if (archived) {
    filters.archived = {
      operator: FaqMenuItemFilterOperator.EQUALS,
      value: true,
    };
  } else if (archived === false) {
    delete filters.archived;
  }

  return {
    id: menu.id,
    organizationReference: organizationReference,
    type: menu.type,
    ...childrenConfig,
    ...(menu.intents && { intents: menu.intents }),
    ...(menu.actionId && { actionId: menu.actionId }),
    ...(Object.keys(filters).length > 0 && { filters: parseFilters(filters) }),
    ...(menu.emoji && { emoji: menu.emoji }),
  };
}

function extractValidFilters(filters: FaqFilters): FaqFilters {
  return Object.entries(filters)
    .filter(([_, value]) => value.operator !== undefined && value.value !== undefined)
    .reduce<FaqFilters>((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
}

function getChildrenConfig(menu: FaqMenuItem, organizationReference: string): { children?: RootItem[] } {
  if (menu.children) {
    return { children: menu.children.map((child) => cleanSubMenu(child, organizationReference)) };
  }
  if (menu.type === FaqMenuItemType.SUB_MENU) {
    return { children: menu.children || [] };
  }
  return {};
}

interface RootItem {
  id: string;
  organizationReference: string;
  emoji?: string;
  type: FaqMenuItemType;
  intents?: string[];
  actionId?: string;
  children?: RootItem[];
  filters?: Record<string, { operator: FaqMenuItemFilterOperator; value: any[] }>;
  archived?: boolean;
}

const updateFaqMenu = (
  menu: FaqMenuItem[],
  ids: string[],
  filters: FaqFilters,
  organizationReference: string,
  archived?: boolean
): RootItem[] => {
  return menu.map((item) => {
    const shouldUpdateItem = ids.includes(item.id);
    const hasChildren = item.children && item.children.length > 0;

    // Process the current item
    const processedItem = cleanSubMenu(
      {
        ...item,
        ...(shouldUpdateItem && { filters }),
      },
      organizationReference,
      shouldUpdateItem ? archived : undefined
    );

    // If the item has children, process them recursively
    if (hasChildren) {
      return {
        ...processedItem,
        children: updateFaqMenu(item.children || [], ids, filters, organizationReference, archived),
      };
    }

    return processedItem;
  });
};

export async function unarchiveKnowledgeDocument(ids: string[], organizationReference: string): Promise<void> {
  try {
    const menu = await fetchFaqsMenu();
    const updatedMenu = updateFaqMenu(menu, ids, {}, organizationReference, false);

    await dashboardAxios.put(`/v1/helpdesk/faqs`, updatedMenu);
  } catch (error) {
    console.error("Error unarchiving knowledge document:", error);
    throw error;
  }
}

export async function updateHelpdeskSection(
  id: string,
  translations: BasicHelpdeskSection,
  parentId?: string
): Promise<void> {
  return dashboardAxios.patch(`/v1/chats/helpdesk/sections/${id}`, { parentId, translations });
}

export async function archiveKnowledgeDocument(ids: string[], organizationReference: string): Promise<void> {
  try {
    const menu = await fetchFaqsMenu();
    const updatedMenu = updateFaqMenu(menu, ids, {}, organizationReference, true);

    await dashboardAxios.put(`/v1/helpdesk/faqs`, updatedMenu);
  } catch (error) {
    console.error("Error archiving knowledge document:", error);
    throw error;
  }
}

export async function updateHelpdeskSectionFilters(id: string, filters: FaqFilters): Promise<void> {
  return await dashboardAxios.put(`/v1/chats/helpdesk/sections/${id}/filters`, { filters });
}

export async function updateHelpdeskItemFilters(id: string, filters: FaqFilters): Promise<void> {
  return await dashboardAxios.put(`/v1/chats/helpdesk/items/${id}/filters`, { filters });
}

export async function updateKnowledgeDocumentFilters(
  ids: string[],
  filters: FaqFilters,
  organizationReference: string
): Promise<void> {
  try {
    const menu = await fetchFaqsMenu();
    const updatedMenu = updateFaqMenu(menu, ids, filters, organizationReference);

    await dashboardAxios.put(`/v1/helpdesk/faqs`, updatedMenu);
  } catch (error) {
    console.error("Error updating knowledge document filters:", error);
    throw error;
  }
}

export async function updateKnowledgeDocument(menu: FaqMenuItem[], organizationReference: string): Promise<void> {
  try {
    const cleanedMenu = menu.map((item) => cleanSubMenu(item, organizationReference));

    return dashboardAxios.put(`/v1/helpdesk/faqs`, cleanedMenu);
  } catch (error) {
    console.error("Error updating menu:", error);
    throw error;
  }
}

export async function updateKnowledgeDocumentPath(knowledgeDocumentId: string, parentId?: string): Promise<void> {
  return await dashboardAxios.patch(`/v1/chats/helpdesk/items/${knowledgeDocumentId}`, { parentId });
}

export async function insertKnowledgeDocument(
  organizationReference: string,
  item: FaqMenuItem,
  parentId?: string
): Promise<void> {
  const menu = await fetchFaqsMenu();

  const deepClone = (items: FaqMenuItem[]): FaqMenuItem[] =>
    items.map((item) => ({
      ...item,
      children: item.children ? deepClone(item.children) : undefined,
    }));

  const clonedMenu = deepClone(menu);

  // Si no hay parentId, añadir el item a la raíz
  if (parentId) {
    // Función auxiliar para buscar recursivamente el nodo padre
    const findAndInsertInNode = (nodes: FaqMenuItem[]): boolean => {
      for (let node of nodes) {
        // Si encontramos el nodo padre, añadimos el item a sus children
        if (node.id === parentId) {
          if (!node.children) {
            node.children = [];
          }
          node.children.push(item);
          return true;
        }
        // Si el nodo tiene hijos, buscamos recursivamente
        if (node.children && node.children.length > 0) {
          if (findAndInsertInNode(node.children)) {
            return true;
          }
        }
      }
      return false;
    };

    // Iniciar la búsqueda recursiva desde la raíz
    const found = findAndInsertInNode(clonedMenu);

    if (!found) {
      throw new Error(`Parent node with id ${parentId} not found`);
    }
  } else {
    clonedMenu.push(item);
  }
  return await updateKnowledgeDocument(clonedMenu, organizationReference);
}
