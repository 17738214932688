import type { FilterValues as AdvanceFilterValues } from "@/components/advanced-filters/useAdvancedFilters";
import type { FilterValues as OldFilterValues } from "@/components/filters/useFilters";
import { sanitizeParamsService, SanitizeParamsType } from "@/utils/sanitizeParamsService";
import { entries, isArray, isNil } from "lodash";

export type AdvanceAndOldFilterValues = AdvanceFilterValues | OldFilterValues;

export function mapAdvanceOrOldFiltersToParams(filters?: AdvanceAndOldFilterValues): SanitizeParamsType {
  const params = {};
  entries(filters || {}).forEach(([filterName, filterValue]) => {
    if (filterValue && filterValue.operator && filterValue.value) {
      const [key, value] = [`${filterName}[${filterValue.operator}]`, filterValue.value];
      params[key] = value;
      return;
    }

    if (!isNil(filterValue)) {
      params[filterName] = isArray(filterValue) ? filterValue.join(",") : filterValue.toString();
      return;
    }
  });

  return sanitizeParamsService(params);
}
