import {
  FilterCategory,
  FilterConfiguration,
  FilterOperatorType,
  FilterType,
  createBooleanFilterConfiguration,
  createDateFilterConfiguration,
  createDriverFilterConfiguration,
  createEmployeeFilterConfiguration,
  createExclusiveSelectFilterConfiguration,
  createHelpdeskFeedbackFilterConfiguration,
  createLeavingReasonTypeFilterConfiguration,
  createMainComparativeDateFilter,
  createMainDateFilter,
  createNumericFilterConfiguration,
  createSelectFilterConfiguration,
  createStringFilterConfiguration,
  createSubDriverFilterConfiguration,
  createTeamFilterConfiguration,
  createUserFilterConfiguration,
} from "@/components/advanced-filters/FilterConfiguration";
import { extractOperatorFromKey } from "@/components/advanced-filters/utils";
import { StructuresWithPermissions } from "@/contexts/AuthContext";
import { EmployeeFieldTranslationGetter, EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";
import {
  ViewOrgDataScope,
  overrideActiveFilterComponentForViewOrgScope,
} from "@/modules/board/components/BoardSwitchScope";
import {
  Board,
  BoardConfig,
  DateFieldReference,
  MetricWidget,
  WidgetMetric,
  isMetricWidget,
} from "@/modules/board/models/board";
import { MetricDataQueryFilter, MetricDataSource } from "@/modules/board/models/metricDataSource";
import { retrocompatibleMetricData } from "@/services/boardService";
import { getDatasetFieldValues } from "@/services/datasetService";
import {
  DateRangeEnumTypeConfig,
  EmployeeFieldType,
  EmployeeFieldValues,
  EmployeeStateValue,
  getEmployeeFieldValues,
} from "@/services/employeeService";
import { getTeamOfManager } from "@/services/teamsService";
import {
  ALL_MODULE_VALUES,
  ALL_REPORTS_CHAT_STATUS_VALUES,
  DatasetColumn,
  DatasetColumnType,
  ChatStatus,
  FAVORABILITY_OPTIONS,
  Gender,
  SegmentOwnerType,
  TicketStatus,
  DatasetView,
} from "@/types/common";
import {
  FavorabilityGender,
  favorabilityLocale,
  moduleLocale,
  chatStatusLocale,
  employeeStateLocale,
  genreLocale as genderLocale,
  ticketStatusLocale,
} from "@/utils/localizeConstants";
import { countryLocale } from "@/utils/localizeCountry";
import { JWTUser } from "@alanszp/jwt";
import { TFunction } from "i18next";
import { camelCase, compact, isArray, partition, uniqBy } from "lodash";

const CUSTOM_FILTERS = ["reachable", "contactEnabled"] as const;

type FilterFactory = (field: EmployeeFieldWithTranslation, locale: string, t: TFunction) => FilterConfiguration;

const FILTER_FACTORY_BY_EMPLOYEE_FIELD_TYPE: Record<EmployeeFieldType, FilterFactory> = {
  [EmployeeFieldType.TEXT]: (field) =>
    createStringFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
    }),
  [EmployeeFieldType.NUMBER]: (field) =>
    createNumericFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
    }),
  [EmployeeFieldType.BOOLEAN]: (field) =>
    createBooleanFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
    }),
  [EmployeeFieldType.SELECT]: (field, locale, t) =>
    createSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
      values: (filters) =>
        getEmployeeFieldValues(field.fieldCode, filters)
          .then((response: EmployeeFieldValues) => {
            return response.fieldValues.map((fieldValue) => ({
              label:
                response.fieldName === "country"
                  ? countryLocale(locale, fieldValue)
                  : response.fieldName === "gender"
                    ? genderLocale(t, fieldValue as Gender)
                    : fieldValue,
              value: fieldValue,
            }));
          })
          .catch((error) => {
            console.error("errorGettingEmployeeFieldValue", error);
            return [];
          }),
    }),
  [EmployeeFieldType.DATE]: (field) =>
    createDateFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
      required: () => false,
      defaultValue: undefined,
    }),
  [EmployeeFieldType.TEAM]: (field) =>
    // TODO Make a new team selector component, that filters by direct, indirect and custom teams based on field config
    createTeamFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
    }),
  [EmployeeFieldType.DATE_RANGE_ENUM]: (field, locale): FilterConfiguration => {
    return createSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
      values: (field.typeConfig as DateRangeEnumTypeConfig).steps.map((step) => ({
        label: step.label[locale],
        value: step.name,
      })),
    });
  },
  [EmployeeFieldType.EMPLOYEE_STATE]: (field, _, t): FilterConfiguration => {
    return createSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
      values: Object.values(EmployeeStateValue).map((value) => ({
        label: employeeStateLocale(t, value),
        value: value,
      })),
    });
  },
  [EmployeeFieldType.EMPLOYEE]: (field) =>
    // TODO Support type config to filter by roles?
    createEmployeeFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
    }),
};

const DATASET_COLUMNS_TO_FILTER: Record<
  DatasetColumnType,
  (dataset: DatasetView, column: DatasetColumn, locale: string) => FilterConfiguration
> = {
  [DatasetColumnType.BOOLEAN]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createBooleanFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      categoryName: dataset.name,
    }),
  [DatasetColumnType.DATE]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createDateFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      required: () => false,
      categoryName: dataset.name,
      defaultValue: undefined,
    }),
  [DatasetColumnType.FLOAT]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createNumericFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      categoryName: dataset.name,
    }),
  [DatasetColumnType.INTEGER]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createNumericFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      categoryName: dataset.name,
    }),
  [DatasetColumnType.TEXT]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createStringFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      categoryName: dataset.name,
    }),
  [DatasetColumnType.TIMESTAMP]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createDateFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      required: () => false,
      categoryName: dataset.name,
      defaultValue: undefined,
    }),
  [DatasetColumnType.UUID]: (dataset: DatasetView, column: DatasetColumn, locale) =>
    createStringFilterConfiguration({
      name: camelCase(column.name),
      label: column.translations[locale],
      category: FilterCategory.DATASET,
      categoryName: dataset.name,
    }),
};

const FILTER_FACTORY_FOR_CUSTOM_FILTERS: Record<(typeof CUSTOM_FILTERS)[number], FilterFactory> = {
  reachable: (field, _, t) =>
    createExclusiveSelectFilterConfiguration({
      name: field.fieldCode,
      label: t("reporting.list.filters.channel"),
      category: FilterCategory.EMPLOYEE,
      operators: [FilterOperatorType.EQUALS],
      values: [
        { value: "true", label: t("reporting.list.filters.reachable.withAtLeastOne") },
        { value: "false", label: t("reporting.list.filters.reachable.withoutChannels") },
      ],
    }),
  // Contact enabled filter has a custom filter chip
  contactEnabled: (field, _, t) =>
    createExclusiveSelectFilterConfiguration({
      name: field.fieldCode,
      label: field.translation,
      category: FilterCategory.EMPLOYEE,
      values: [
        { value: "true", label: t("reporting.list.filters.contactEnabled.activeChipLabel") },
        { value: "false", label: t("reporting.list.filters.contactEnabled.inactiveChipLabel") },
      ],
    }),
};

const filterFactoryByEmployeeField = (
  field: EmployeeFieldWithTranslation,
  locale: string,
  t: TFunction
): FilterConfiguration => {
  // Some fields have custom filters, we need to handle them separately
  if (CUSTOM_FILTERS.includes(field.typeConfig.customFilter as (typeof CUSTOM_FILTERS)[number])) {
    return FILTER_FACTORY_FOR_CUSTOM_FILTERS[field.typeConfig.customFilter as (typeof CUSTOM_FILTERS)[number]](
      field,
      locale,
      t
    );
  }

  const filterFactory = FILTER_FACTORY_BY_EMPLOYEE_FIELD_TYPE[field.type];
  return filterFactory(field, locale, t);
};

export const isMultipleOperator = (operator: FilterOperatorType): boolean =>
  [FilterOperatorType.IN, FilterOperatorType.NOT_IN].includes(operator);

/**
 * Filters available for all HE metrics
 */
export const commonHEFilters = (
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[]
): FilterConfiguration[] => {
  const dynamicFilters = employeeFields
    .filter((field) => field.options.actions.allowFiltering)
    .map((field) => filterFactoryByEmployeeField(field, locale, t));

  // TODO Support team filters to be constructed from custom, direct and indirect teams filters
  const [teamFilters, _otherFilters] = partition(dynamicFilters, (filter) => filter.type === FilterType.TEAM);

  const [leavingReasonTypeFilter, otherFilters] = partition(
    _otherFilters,
    (filter) => filter.name === "leavingReasonType"
  );

  return compact([
    ...otherFilters,
    // Check if the leavingReasonType filter is present, if so, we add it to the filters with custom values
    leavingReasonTypeFilter.length > 0
      ? createLeavingReasonTypeFilterConfiguration(t, getEmployeeFieldTranslation)
      : undefined,
    // For now, if is at least one team filter, we add the generic team filter
    teamFilters.length > 0
      ? createTeamFilterConfiguration({
          name: "teamId",
          label: t("reporting.list.filters.team"),
          required: (user): boolean =>
            !(user?.role.segmentOwnerType !== SegmentOwnerType.MANAGER || !user.employeeReference),
          defaultValue: async (user): Promise<string | null> => {
            // Managers by default will see their indirect team as the default team
            // If the user is not a manager or doesn't have a employee, we return null
            if (user?.role.segmentOwnerType !== SegmentOwnerType.MANAGER || !user.employeeReference) {
              return null;
            }
            // If the user is a manager, we find the team and return the indirect one
            const team = await getTeamOfManager(user.employeeReference);
            return team?.indirectReportTeamId ?? null;
          },
        })
      : undefined,
  ]);
};

async function calculateScopesForUser(t: TFunction, jwtUser: JWTUser | null) {
  const allOrgPermission = (await jwtUser?.hasPermission("reporting.scope.org:fetch")) ?? false;

  return [
    {
      value: ViewOrgDataScope.MY_EMPLOYEES,
      label: t("board.detail.widget.filters.viewOrgData.myEmployees"),
    },
    ...(allOrgPermission
      ? [
          {
            value: ViewOrgDataScope.ALL_ORG,
            label: t("board.detail.widget.filters.viewOrgData.allOrg"),
          },
        ]
      : []),
  ];
}

export function createReportScopeFilter(t: TFunction, jwtUser: JWTUser | null): FilterConfiguration {
  return createExclusiveSelectFilterConfiguration({
    name: "viewOrgData",
    label: t("board.detail.widget.filters.viewOrgData.label"),
    category: FilterCategory.OTHER,
    values: () => calculateScopesForUser(t, jwtUser),
    disabled: jwtUser?.segmentReference === null,
    required: () => true,
    defaultValue: async () => {
      const scopes = await calculateScopesForUser(t, jwtUser);
      const allOrgValue = scopes.find((scope) => scope.value === ViewOrgDataScope.ALL_ORG);
      const myEmployeesValue = scopes.find((scope) => scope.value === ViewOrgDataScope.MY_EMPLOYEES);
      if (jwtUser?.segmentReference === null) {
        return allOrgValue ?? myEmployeesValue!;
      }
      return myEmployeesValue!;
    },
    operators: [FilterOperatorType.EQUALS],
    overrideActiveFilterComponent: overrideActiveFilterComponentForViewOrgScope,
  });
}

/**
 * Returns the global filters available in a Board
 */
export const getGlobalFiltersForBoard = (
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  jwtUser: JWTUser | null,
  employeeFields: EmployeeFieldWithTranslation[],
  withPermissions: StructuresWithPermissions,
  board?: Board
): FilterConfiguration[] => {
  const widgetMetricAdditionalFilters: FilterConfiguration[] = (board?.widgets?.filter(isMetricWidget) ?? [])
    .map((widget) =>
      getFilterConfigurationsForWidget(
        widget,
        t,
        getEmployeeFieldTranslation,
        locale,
        employeeFields,
        withPermissions,
        board?.config
      )
    )
    .flat()
    .filter((filter) => {
      const employeeField = employeeFields.find((field) => field.fieldCode === filter.name);
      // If the field is an employee field, we only show it if it allows filtering
      return (
        !employeeField ||
        (employeeField.options.actions.allowFiltering && employeeField.options.visibility.showInFilters)
      );
    });

  return uniqBy(
    compact([createReportScopeFilter(t, jwtUser), ...widgetMetricAdditionalFilters]),
    (f) => `${f.category}@@${f.categoryName}@@${f.name}`
  );
};

/**
 * Returns the global filters available for communications
 */
export const getFiltersForCommunications = (
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[]
): FilterConfiguration[] => {
  return commonHEFilters(
    t,
    getEmployeeFieldTranslation,
    locale,
    employeeFields.filter((field) => field.fieldCode !== "tenure")
  );
};

// Source of truth for the filters available for each metric
// This is used to generate the filters in the advanced filters component
// Will come in handy when we add more metrics and create the widget wizard
export const AVAILABLE_FILTERS_FOR_METRIC: Record<
  WidgetMetric,
  (
    t: TFunction,
    getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
    locale: string,
    employeeFields: EmployeeFieldWithTranslation[],
    withPermissions: StructuresWithPermissions,
    source?: MetricDataSource
  ) => FilterConfiguration[]
> = {
  [WidgetMetric.ENPS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.ENPS_ANSWERS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "detractor", label: t("board.detail.widget.filters.labels.detractor") },
        { value: "passive", label: t("board.detail.widget.filters.labels.passive") },
        { value: "promoter", label: t("board.detail.widget.filters.labels.promoter") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "contactStatus",
      label: t("board.detail.widget.filters.contactStatus"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "contacted", label: t("board.detail.widget.filters.labels.contacted") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answerStatus",
      category: FilterCategory.CHAT_AND_ANSWERS,
      label: t("board.detail.widget.filters.answerStatus"),
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.QUESTIONS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "questionType",
      category: FilterCategory.CHAT_AND_ANSWERS,
      label: t("board.detail.widget.filters.questionType"),
      values: [
        { value: "mood", label: t("board.detail.widget.filters.labels.questionType.mood") },
        { value: "two_options", label: t("board.detail.widget.filters.labels.questionType.two_options") },
        { value: "three_options", label: t("board.detail.widget.filters.labels.questionType.three_options") },
        { value: "five_options", label: t("board.detail.widget.filters.labels.questionType.five_options") },
        { value: "seven_options", label: t("board.detail.widget.filters.labels.questionType.seven_options") },
        { value: "text", label: t("board.detail.widget.filters.labels.questionType.text") },
        { value: "nps", label: t("board.detail.widget.filters.labels.questionType.nps") },
        { value: "recognition", label: t("board.detail.widget.filters.labels.questionType.recognition") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "questionModule",
      label: t("reporting.list.filters.module"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createExclusiveSelectFilterConfiguration({
      name: "answered",
      label: t("board.detail.widget.filters.answered"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: FAVORABILITY_OPTIONS.map((favorability) => ({
        value: favorability,
        label: favorabilityLocale(t, favorability, FavorabilityGender.FEMALE),
      })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "questionId",
      label: t("board.detail.widget.filters.questionId"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      overrideActiveFilterLabel: (_, operator, value) => {
        const count = isMultipleOperator(operator) && isArray(value) ? value.length : 1;
        return t("board.detail.widget.filters.labels.questionId", { count });
      },
      hideAsNewFilter: true,
    }),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      category: FilterCategory.CHAT_AND_ANSWERS,
      label: t("board.detail.widget.filters.touchpointId"),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      category: FilterCategory.CHAT_AND_ANSWERS,
      label: t("board.detail.widget.filters.touchpointName"),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.RESPONSE_RATE]: (t, org, locale, fields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, fields),
  ],
  [WidgetMetric.INDIVIDUAL_RESPONSES]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "questionType",
      label: t("board.detail.widget.filters.questionType"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "mood", label: t("board.detail.widget.filters.labels.questionType.mood") },
        { value: "two_options", label: t("board.detail.widget.filters.labels.questionType.two_options") },
        { value: "three_options", label: t("board.detail.widget.filters.labels.questionType.three_options") },
        { value: "five_options", label: t("board.detail.widget.filters.labels.questionType.five_options") },
        { value: "seven_options", label: t("board.detail.widget.filters.labels.questionType.seven_options") },
        { value: "text", label: t("board.detail.widget.filters.labels.questionType.text") },
        { value: "nps", label: t("board.detail.widget.filters.labels.questionType.nps") },
        { value: "recognition", label: t("board.detail.widget.filters.labels.questionType.recognition") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "questionModule",
      label: t("reporting.list.filters.module"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answered",
      label: t("board.detail.widget.filters.answered"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
      overrideActiveFilterLabel: (_, __, value) =>
        value === "answered"
          ? t("board.detail.widget.filters.labels.answeredFilterLabel")
          : t("board.detail.widget.filters.labels.pendingAnswerFilterLabel"),
    }),
    createSelectFilterConfiguration({
      name: "favorability",
      label: t("board.detail.widget.filters.favorability"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: FAVORABILITY_OPTIONS.map((favorability) => ({
        value: favorability,
        label: favorabilityLocale(t, favorability, FavorabilityGender.FEMALE),
      })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "questionId",
      label: t("board.detail.widget.filters.questionId"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      overrideActiveFilterLabel: (_, operator, value) => {
        const count = isMultipleOperator(operator) && isArray(value) ? value.length : 1;
        return t("board.detail.widget.filters.labels.questionId", { count });
      },
      hideAsNewFilter: true,
    }),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      label: t("board.detail.widget.filters.touchpointId"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      label: t("board.detail.widget.filters.touchpointName"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.CASES]: (t, org, locale, fields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    createExclusiveSelectFilterConfiguration({
      name: "dateFieldFilter",
      label: t("board.detail.widget.filters.dateFieldFilter"),
      category: FilterCategory.DATE,
      values: [
        { value: DateFieldReference.DETECTED_AT, label: t("board.detail.widget.filters.labels.detected_at") },
        { value: DateFieldReference.OPENED_AT, label: t("board.detail.widget.filters.labels.opened_at") },
        { value: DateFieldReference.CLOSED_AT, label: t("board.detail.widget.filters.labels.closed_at") },
      ],
      required: () => true,
      operators: [FilterOperatorType.EQUALS],
      defaultOperator: FilterOperatorType.EQUALS,
      hideAsNewFilter: true,
    }),
    ...commonHEFilters(t, org, locale, fields),
    createSelectFilterConfiguration({
      name: "status",
      label: t("board.detail.widget.filters.caseStatus"),
      category: FilterCategory.CASES,
      values: [
        { value: "detected", label: t("board.detail.widget.filters.labels.detected") },
        { value: "inProgress", label: t("board.detail.widget.filters.labels.inProgress") },
        { value: "closed", label: t("board.detail.widget.filters.labels.closed") },
      ],
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "severity",
      label: t("board.detail.widget.filters.severity"),
      category: FilterCategory.CASES,
      values: [
        { value: "low", label: t("board.detail.widget.filters.labels.low") },
        { value: "medium", label: t("board.detail.widget.filters.labels.medium") },
        { value: "high", label: t("board.detail.widget.filters.labels.high") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.MOOD]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.MOOD_ANSWERS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "answerStatus",
      label: t("board.detail.widget.filters.answerStatus"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.DRIVER]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.DRIVER_ANSWERS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createDriverFilterConfiguration({
      name: "driverCode",
      label: t("board.detail.widget.filters.driverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createSubDriverFilterConfiguration({
      name: "subDriverCode",
      label: t("board.detail.widget.filters.subDriverCode"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "answerStatus",
      label: t("board.detail.widget.filters.answerStatus"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: [
        { value: "neverContacted", label: t("board.detail.widget.filters.labels.neverContacted") },
        { value: "pendingAnswer", label: t("board.detail.widget.filters.labels.pendingAnswer") },
        { value: "answered", label: t("board.detail.widget.filters.labels.answered") },
      ],
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.LARA_SCORE]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.CHATS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "module",
      label: t("reporting.list.filters.module"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: ALL_MODULE_VALUES.map((module) => ({ value: module, label: moduleLocale(t, module) })),
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "chatStatus",
      label: t("board.detail.widget.filters.chatStatus"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: ALL_REPORTS_CHAT_STATUS_VALUES.map((status) => ({ value: status, label: chatStatusLocale(t, status) })),
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointId",
      label: t("board.detail.widget.filters.touchpointId"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
    createStringFilterConfiguration({
      name: "touchpointName",
      label: t("board.detail.widget.filters.touchpointName"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.EMPLOYEES]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    createMainComparativeDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.ONBOARDING_AND_OFFBOARDING]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.METRIC_HELPDESK_CONVERSATIONS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createHelpdeskFeedbackFilterConfiguration(t),
  ],
  [WidgetMetric.OFFBOARDING]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createSelectFilterConfiguration({
      name: "chatStatus",
      label: t("reporting.list.filters.leavingReasonType"),
      category: FilterCategory.CHAT_AND_ANSWERS,
      values: uniqBy(
        Object.values(ChatStatus).map((status) => ({
          value: status,
          label: chatStatusLocale(t, status),
        })),
        "label"
      ),
      hideAsNewFilter: true,
    }),
  ],
  [WidgetMetric.METRIC_HELPDESK_USED_ITEMS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
    createHelpdeskFeedbackFilterConfiguration(t),
  ],
  [WidgetMetric.LISTING_FEEDBACKS]: (t, org, locale, employeeFields) => [
    createMainDateFilter(t),
    ...commonHEFilters(t, org, locale, employeeFields),
  ],
  [WidgetMetric.METRIC_TICKETS]: (t, org, locale, fields, withPermissions) => [
    createMainDateFilter(t),
    createExclusiveSelectFilterConfiguration({
      name: "dateFieldFilter",
      label: t("board.detail.widget.filters.dateFieldFilter"),
      category: FilterCategory.DATE,
      values: [
        { value: DateFieldReference.CREATED_AT, label: t("board.detail.widget.filters.labels.created_at") },
        { value: DateFieldReference.UPDATED_AT, label: t("board.detail.widget.filters.labels.updated_at") },
        { value: DateFieldReference.DOING_AT, label: t("board.detail.widget.filters.labels.doing_at") },
        { value: DateFieldReference.CLOSED_AT, label: t("board.detail.widget.filters.labels.closed_at") },
      ],
      required: () => true,
      operators: [FilterOperatorType.EQUALS],
      defaultOperator: FilterOperatorType.EQUALS,
      hideAsNewFilter: true,
    }),
    ...commonHEFilters(t, org, locale, fields),
    createSelectFilterConfiguration({
      name: "status",
      label: t("ticket.list.filters.status"),
      category: FilterCategory.TICKET,
      required: () => false,
      values: [TicketStatus.TO_DO, TicketStatus.DOING, TicketStatus.CLOSED].map((status) => ({
        value: status,
        label: ticketStatusLocale(t, status),
      })),
      operators: [FilterOperatorType.IN, FilterOperatorType.NOT_IN],
      defaultOperator: FilterOperatorType.IN,
      hideAsNewFilter: true,
    }),
    createUserFilterConfiguration({
      name: "assignedTo",
      label: t("board.detail.widget.filters.assignedTo"),
      category: FilterCategory.TICKET,
      operators: [FilterOperatorType.EQUALS],
      hideAsNewFilter: true,
    }),
    createEmployeeFilterConfiguration({
      name: "employeeReference",
      label: t("board.detail.widget.filters.employeeReference"),
      category: FilterCategory.TICKET,
      hideAsNewFilter: true,
    }),
    createSelectFilterConfiguration({
      name: "ticketType",
      label: t("ticket.list.filters.ticketType"),
      category: FilterCategory.TICKET,
      required: () => false,
      values: withPermissions.ticketTypes.map((ticketType) => ({
        value: ticketType.code,
        label: `(${ticketType.code}) ${ticketType.name}`,
      })),
      operators: [FilterOperatorType.IN, FilterOperatorType.NOT_IN],
      defaultOperator: FilterOperatorType.IN,
    }),
  ],
  [WidgetMetric.LISTING_DATASET]: (_t, _org, locale, _employeeFields, withPermissions, source) => {
    const dataset = withPermissions.datasets.find(
      (dataset) =>
        dataset.id === source?.query?.filters?.dataset || dataset.id === source?.query?.statics?.filters?.dataset
    );

    if (!dataset) return [];

    return compact(
      dataset.configurations.schema.columns
        .filter((c) => !c.visualization.hideFromFilters)
        .map((column) => {
          if (column.visualization.showAsSelect) {
            return createSelectFilterConfiguration({
              name: camelCase(column.name),
              label: column.translations[locale],
              category: FilterCategory.DATASET,
              categoryName: dataset.name,
              values: (filters) =>
                getDatasetFieldValues(dataset.id, column.name, filters)
                  .then((fieldValues: string[]) => {
                    return fieldValues.map((fieldValue) => ({
                      label: fieldValue,
                      value: fieldValue,
                    }));
                  })
                  .catch((error) => {
                    console.error("errorGettingDatasetFieldValue", error);
                    return [];
                  }),
            });
          }

          return DATASET_COLUMNS_TO_FILTER[column.type]?.(dataset, column, locale);
        })
    );
  },
};

export function getFilterConfigurationsForWidget(
  widget: MetricWidget,
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[],
  withPermissions: StructuresWithPermissions,
  boardConfig?: BoardConfig
): FilterConfiguration[] {
  const widgetData = retrocompatibleMetricData(widget);
  const sources = widgetData.sources;

  const filters = uniqBy(
    sources.flatMap((source) =>
      getFilterConfigurationsForMetric(
        source.query.metric,
        t,
        getEmployeeFieldTranslation,
        locale,
        employeeFields,
        withPermissions,
        source
      )
    ),
    "name"
  );

  if (!boardConfig?.showHiddenFilters) return filters;

  const showHiddenFilters = new Set(boardConfig.showHiddenFilters ?? []);

  return filters.map((f) => ({ ...f, hideAsNewFilter: showHiddenFilters.has(f.name) ? false : f.hideAsNewFilter }));
}

export function getFilterConfigurationsForMetric(
  metric: WidgetMetric,
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  employeeFields: EmployeeFieldWithTranslation[],
  withPermissions: StructuresWithPermissions,
  source?: MetricDataSource
): FilterConfiguration[] {
  return (
    AVAILABLE_FILTERS_FOR_METRIC[metric]?.(
      t,
      getEmployeeFieldTranslation,
      locale,
      employeeFields,
      withPermissions,
      source
    ) ?? []
  );
}

export const OperatorEnum = {
  EQUAL: "eq",
  NOT_EQUAL: "notEq",
  INVALID_OPERATOR: "invalidOperator", // use for validation
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OperatorEnum = (typeof OperatorEnum)[keyof typeof OperatorEnum];

export const DateOperatorEnum = {
  ...OperatorEnum,
  BETWEEN: "between",
  AFTER: "after",
  BEFORE: "before",
} as const;

export const DATE_OPERATOR_ENUM = Object.values(DateOperatorEnum);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DateOperatorEnum = (typeof DateOperatorEnum)[keyof typeof DateOperatorEnum];

export function mergeAndOverrideFilters(...filters: MetricDataQueryFilter[]): MetricDataQueryFilter {
  return Object.fromEntries(
    filters
      .reduce((acc, filter) => {
        Object.entries(filter).forEach(([key, value]) => {
          const [filterKey] = extractOperatorFromKey(key);

          // If the filter is already present in filters,
          // we have to remove it, no matter the operator

          // Remove the standalone filter
          acc.delete(filterKey);
          // Remove the filter with operators
          Object.values(FilterOperatorType).forEach((operator) => {
            const filterKeyWithOperator = `${filterKey}[${operator}]`;
            acc.delete(filterKeyWithOperator);
          });

          // Set the global filter
          acc.set(key, value.toString());
        });

        return acc;
      }, new URLSearchParams())
      .entries()
  );
}
