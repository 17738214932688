import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Tab, Tabs, Tooltip } from "@mui/material";
import { ErrorBox } from "@/components/ErrorBox";
import { useQuery } from "@tanstack/react-query";
import { TranslationLocales, TranslationLocalesValues } from "@/types/common";
import useAuth from "@/hooks/useAuth";
import { getOrganizationLocales } from "@/services/organizationService";
import { HelpdeskTreeView } from "@/components/helpdesk-tree-view";
import { InputAutocompleteEmployee } from "@/components/InputAutocompleteEmployee";
import {
  FaqMenuItemType,
  fetchHelpdeskMenu,
  HelpdeskMenuBaseItem,
  RelevantNode,
  searchKnowledgeBase,
} from "@/services/faqsService";

export const HelpdeskPageTestContainer: FC = () => {
  const { t } = useTranslation();
  const { organizationReference, user } = useAuth();
  const [searchingLocale, setSearchingLocale] = useState<TranslationLocales | null>(null);
  const [locales, setLocales] = useState<TranslationLocales[]>([]);
  const [showLocalesError, setShowLocalesError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(user?.employeeReference ?? null);
  const hasMoreThanOneLocale = locales.length > 1;
  const [helpdeskMenuResponse, setHelpdeskMenuResponse] = useState<HelpdeskMenuBaseItem[]>([]);
  const localesQuery = useQuery({
    queryKey: ["organization.locales"],
    queryFn: () => getOrganizationLocales(),
  });

  const testQuery = useQuery({
    queryKey: ["knowledge-base-menu", searchQuery, searchingLocale, selectedEmployee],
    queryFn: () => {
      if (!searchQuery || !searchingLocale) return null;
      return searchKnowledgeBase({
        locale: searchingLocale,
        message: searchQuery,
        employeeReference: selectedEmployee ?? undefined,
        maxResults: 999,
      }).then((res) => new Map(res.topNodes?.map((node) => [node.itemId, node]) || []));
    },
    staleTime: 0,
    enabled: searchQuery !== "",
  });

  const helpdeskMenuQuery = useQuery({
    queryKey: ["helpdesk-menu", searchingLocale],
    queryFn: () => fetchHelpdeskMenu(searchingLocale!, organizationReference!),
    enabled: !!searchingLocale,
  });

  useEffect(() => {
    if (searchQuery === "") {
      setHelpdeskMenuResponse([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (testQuery && testQuery.data) {
      const filterFn = (item: HelpdeskMenuBaseItem, topNodes: Map<string, RelevantNode>) => {
        return (item.type === FaqMenuItemType.FAQS && topNodes.has(item.id)) ?? false;
      };

      const flattenAndFilter = (
        items: HelpdeskMenuBaseItem[],
        results: HelpdeskMenuBaseItem[] = [],
        topNodes: Map<string, RelevantNode>
      ): HelpdeskMenuBaseItem[] => {
        items.forEach((item) => {
          if (filterFn(item, topNodes)) {
            results.push(item);
          }

          if (item.children && item.children.length > 0) {
            flattenAndFilter(item.children, results, topNodes);
          }
        });

        return results;
      };

      if (helpdeskMenuQuery.data && testQuery.data !== null) {
        setHelpdeskMenuResponse(flattenAndFilter(helpdeskMenuQuery.data, [], testQuery.data));
      }
    }
  }, [testQuery.data]);

  useEffect(() => {
    if (!localesQuery.isFetched) {
      return;
    }

    if ((localesQuery.data && localesQuery.data.length === 0) || localesQuery.data === undefined) {
      setShowLocalesError(true);
      return;
    }

    if (searchingLocale === null) {
      setSearchingLocale(localesQuery.data[0]);
      setLocales(localesQuery.data);
    }
  }, [localesQuery.data]);

  if (showLocalesError) {
    return (
      <Box>
        <ErrorBox subtitle={t("helpdesk.list.noLocalesFound")} icon={true} />
      </Box>
    );
  }

  const handleOnChangeLocale = (locale: TranslationLocales) => {
    setSearchingLocale(locale);
    setSearchQuery("");
    setHelpdeskMenuResponse([]);
  };

  return (
    <>
      {localesQuery.isPending ? null : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
            {hasMoreThanOneLocale ? (
              <Tabs
                value={searchingLocale}
                aria-label="locale-tabs"
                onChange={(_, locale) => handleOnChangeLocale(locale)}
              >
                {locales.map((locale) => (
                  <Tab
                    key={locale}
                    label={
                      <Tooltip title={TranslationLocalesValues[locale]}>
                        <>{locale}</>
                      </Tooltip>
                    }
                    value={locale}
                  />
                ))}
              </Tabs>
            ) : null}
            <Box sx={{ ml: "auto", display: "flex", gap: 2, pb: 1 }}>
              <InputAutocompleteEmployee
                name="employee"
                fullWidth
                size="small"
                label={t("helpdesk.list.search.seeAs")}
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value as string)}
              />
            </Box>
          </Box>
          <Card sx={{ padding: 2, ...(hasMoreThanOneLocale && { borderTopLeftRadius: 0 }) }}>
            <HelpdeskTreeView
              loading={testQuery.isFetching}
              data={helpdeskMenuResponse}
              setData={setHelpdeskMenuResponse}
              allowEdit={false}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchFn={(data) => data}
              searchLabel={t("helpdesk.test.search.label")}
              noResultsText={
                searchQuery
                  ? t("helpdesk.test.search.noResults", { search: searchQuery })
                  : t("helpdesk.test.search.startTyping")
              }
            />
          </Card>
        </>
      )}
    </>
  );
};
