import { HelpdeskMenuBaseItem } from "@/services/faqsService";

import { TreeItem } from "@mui/lab";
import { Box, IconButton, styled, TextField, Typography } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { updateNodeDescription, updateNodeResponse } from "./utils";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));
StyledIconButton.defaultProps = {
  size: "small",
};

interface HelpdeskTreeItemsProps {
  data: HelpdeskMenuBaseItem[];
  rootData: HelpdeskMenuBaseItem[];
  setData: (data: HelpdeskMenuBaseItem[]) => void;
  editingNodeId: string | null;
  setEditingNodeId: (nodeId: string | null) => void;
  editingNodeIdResponse: string | null;
  setEditingNodeIdResponse: (nodeId: string | null) => void;
  filterFn?: (item: HelpdeskMenuBaseItem) => boolean;
  actions?: (item: HelpdeskMenuBaseItem) => React.ReactNode;
  allowEdit?: boolean;
}

const HelpdeskTreeItems = ({
  data,
  rootData,
  setData,
  editingNodeId,
  setEditingNodeId,
  editingNodeIdResponse,
  setEditingNodeIdResponse,
  filterFn,
  actions,
  allowEdit = true,
}: HelpdeskTreeItemsProps) => {
  const safeData = Array.isArray(data) ? data : [];
  const filteredNodes = filterFn ? safeData.filter(filterFn) : safeData;

  if (!data || !rootData || filteredNodes.length === 0) {
    return <></>;
  }

  return (
    <>
      {filteredNodes.map((node) => {
        if (!node) return <></>;

        return (
          <TreeItem
            key={node.id}
            nodeId={node.id}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {editingNodeId === node.id ? (
                  <>
                    <TextField
                      value={node.description || ""}
                      variant="standard"
                      fullWidth
                      autoFocus
                      onBlur={() => setEditingNodeId(null)}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const updatedNodes = updateNodeDescription(rootData, node.id, e.target.value);
                        setData(updatedNodes);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEditingNodeId(null);
                        }
                      }}
                    />
                    <StyledIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingNodeId(null);
                      }}
                    >
                      <SaveIcon fontSize="small" />
                    </StyledIconButton>
                  </>
                ) : (
                  <>
                    <Typography>{node.description}</Typography>
                    {actions ? actions(node) : null}
                    {allowEdit && (
                      <StyledIconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingNodeId(node.id);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </StyledIconButton>
                    )}
                  </>
                )}
              </Box>
            }
            sx={{
              "& .MuiTreeItem-content": {
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                mb: 1,
                padding: 1,
              },
            }}
          >
            {Array.isArray(node.children) ? (
              <HelpdeskTreeItems
                data={node.children}
                rootData={rootData}
                setData={setData}
                editingNodeId={editingNodeId}
                setEditingNodeId={setEditingNodeId}
                editingNodeIdResponse={editingNodeIdResponse}
                setEditingNodeIdResponse={setEditingNodeIdResponse}
                filterFn={filterFn}
                actions={actions}
                allowEdit={allowEdit}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{
                  padding: 2,
                  paddingTop: 1,
                }}
              >
                {editingNodeIdResponse === node.id ? (
                  <>
                    <TextField
                      value={node.response || ""}
                      variant="standard"
                      fullWidth
                      multiline
                      autoFocus
                      onBlur={() => setEditingNodeIdResponse(null)}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const updatedNodes = updateNodeResponse(rootData, node.id, e.target.value);
                        setData(updatedNodes);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setEditingNodeIdResponse(null);
                        }
                      }}
                    />
                    <StyledIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingNodeIdResponse(null);
                      }}
                    >
                      <SaveIcon fontSize="small" />
                    </StyledIconButton>
                  </>
                ) : (
                  <>
                    <Typography>{node.response}</Typography>
                    {allowEdit && (
                      <StyledIconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingNodeIdResponse(node.id);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </StyledIconButton>
                    )}
                  </>
                )}
              </Box>
            )}
          </TreeItem>
        );
      })}
    </>
  );
};

export { HelpdeskTreeItems };
