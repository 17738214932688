import { TreeView } from "@mui/lab";
import { HelpdeskTreeItems } from "./HelpdeskTreeItems";
import { HelpdeskMenuBaseItem } from "@/services/faqsService";
import { useState, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Chip, Skeleton } from "@mui/material";
import { Typography } from "@mui/material";
import { SearchInput } from "../SearchInput";
import { countResponses, searchInTree } from "./utils";
import { useTranslation } from "react-i18next";
import { keyframes } from "@mui/system";

type HelpdeskTreeViewProps = {
  data: HelpdeskMenuBaseItem[];
  setData: (data: HelpdeskMenuBaseItem[]) => void;
  actions?: (item: HelpdeskMenuBaseItem) => React.ReactNode;
  allowEdit?: boolean;
  onChangeSearch?: (search: string) => void;
  searchQuery?: string;
  setSearchQuery?: (search: string) => void;
  searchFn?: (data: HelpdeskMenuBaseItem[]) => HelpdeskMenuBaseItem[];
  noResultsText?: string;
  loading?: boolean;
  searchLabel?: string;
};

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HelpdeskTreeView = ({
  data,
  setData,
  actions,
  allowEdit = true,
  searchQuery,
  setSearchQuery,
  searchFn,
  noResultsText,
  loading = false,
  searchLabel,
}: HelpdeskTreeViewProps) => {
  const [editingNodeId, setEditingNodeId] = useState<string | null>(null);
  const [editingNodeIdResponse, setEditingNodeIdResponse] = useState<string | null>(null);

  const { t } = useTranslation();

  const filteredData = useMemo(() => {
    return searchQuery ? (searchFn ? searchFn(data) : searchInTree(data, searchQuery)) : data;
  }, [data, searchQuery]);

  const chipLabel = searchQuery
    ? t("helpdesk.search.results", {
        count: countResponses(filteredData),
        total: countResponses(data),
        search: searchQuery,
      })
    : t("helpdesk.search.total", { count: countResponses(data) });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box display="flex" gap={1.5} alignItems="baseline" justifyContent="space-between">
        <SearchInput
          name="search"
          fullWidth
          label={searchLabel ?? t("common.actions.search")}
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery?.(e.target.value)}
          variant="outlined"
          size="small"
        />
        <Chip label={chipLabel} {...(searchQuery ? { onDelete: () => setSearchQuery?.("") } : {})} />
      </Box>

      {loading ? (
        <Skeleton variant="text" height={100} />
      ) : filteredData.length === 0 ? (
        <Typography variant="body1">
          {noResultsText ?? t("helpdesk.search.noResults", { search: searchQuery })}
        </Typography>
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["root"]}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            "& .MuiTreeItem-root": {
              opacity: 0,
              animation: `${fadeIn} 0.5s ease forwards`,
              ...Array.from({ length: filteredData.length }).reduce<Record<string, { animationDelay: string }>>(
                (acc, _, i) => ({
                  ...acc,
                  [`&:nth-of-type(${i + 1})`]: {
                    animationDelay: `${i * 0.1}s`,
                  },
                }),
                {}
              ),
            },
          }}
        >
          <HelpdeskTreeItems
            rootData={data}
            data={filteredData}
            setData={setData}
            editingNodeId={editingNodeId}
            setEditingNodeId={setEditingNodeId}
            editingNodeIdResponse={editingNodeIdResponse}
            setEditingNodeIdResponse={setEditingNodeIdResponse}
            actions={actions}
            allowEdit={allowEdit}
          />
        </TreeView>
      )}
    </Box>
  );
};

export { HelpdeskTreeView };
