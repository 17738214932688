import { FC } from "react";
import Page from "@/components/Page";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Link from "@mui/material/Link";
import { IconButton } from "@mui/material";
import { PromptCreateContainer } from "../components/prompts/PromptCreateContainer";

const PromptSectionCreatePage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page
      track={{ pageName: "promptsSectionCreate" }}
      locales={{
        title: t("settings.prompts.create.title"),
        headTitle: t("settings.prompts.create.title"),
      }}
      preButton={
        <Link underline="none" color="textSecondary" component={RouterLink} to="/settings/prompts" variant="body2">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Link>
      }
    >
      <PromptCreateContainer />
    </Page>
  );
};

export default PromptSectionCreatePage;
