import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AvatarSize, EmployeeSummary } from "@/types/common";
import { Popper, Skeleton } from "@mui/material";
import { FC, MutableRefObject } from "react";
import UserAvatar from "../UserAvatar";
import isFormerEmployee from "@/utils/isFormerEmployee";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { getEmployeeSummary } from "@/services/employeeService";
import { ProfileItemInfo } from "./ProfileItemInfo";
import { usePromise } from "@/hooks/usePromise";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";
import { bindPopper } from "material-ui-popup-state";
// import CallIcon from "@mui/icons-material/Call";

const SKIP_FIELD_CODE_IN_PROFILE_SUMMARY_ITEMS = [
  "jobTitle", // Skip job title because it is already displayed in the employee card
];

interface ProfileSummaryProps {
  anchorEl: MutableRefObject<HTMLElement | undefined> | null;
  size?: AvatarSize;
  employeeReference: string;
  popupState: any;
}

const cacheByEmployeeReference: Record<string, EmployeeSummary> = {};

export const ProfileSummaryLayout: FC<{
  popupState: any;
  children: React.ReactNode;
}> = ({ children, popupState }) => {
  return (
    <Popper
      {...bindPopper(popupState)}
      placement="top-start"
      onClick={(e) => {
        e.stopPropagation();
        // Prevent the click event from bubbling up to the parent element
      }}
    >
      <Card sx={{ pb: 0, minWidth: 180 }}>{children}</Card>
    </Popper>
  );
};

export const ProfileSummary: FC<ProfileSummaryProps> = ({
  popupState,
  employeeReference,
  size = AvatarSize.NORMAL,
  anchorEl,
}) => {
  const { fields, findEmployeeField } = useEmployeeFields();

  const fontSize = size === AvatarSize.SMALL ? 10 : 12;

  const fetchEmployee = usePromise(async () => {
    if (anchorEl && anchorEl?.current && popupState.isOpen && employeeReference) {
      if (cacheByEmployeeReference[employeeReference]) {
        return cacheByEmployeeReference[employeeReference];
      }
      const employee = await getEmployeeSummary(employeeReference);
      cacheByEmployeeReference[employeeReference] = employee;

      return employee;
    }
  }, [employeeReference, popupState.isOpen]);

  if (!anchorEl || !anchorEl?.current) {
    return null;
  }

  const employeeMetadataFields = fields.filter((f) => f.options.visibility.showInEmployeeSummary);

  return (
    <ProfileSummaryLayout popupState={popupState}>
      {fetchEmployee.render(
        () => (
          <>
            <CardHeader
              avatar={<UserAvatar skeleton size={size} name={""} />}
              title={<Skeleton width={100} height={10} variant="rectangular" sx={{ borderRadius: 1, mb: 1 }} />}
              subheader={<Skeleton width={120} height={8} variant="rectangular" sx={{ borderRadius: 1 }} />}
            />
            <CardContent sx={{ py: 0, pb: (t) => `${t.spacing(1)}!important` }}>
              <Skeleton width={"100%"} />
              <Skeleton width={"100%"} />
              <Skeleton width={"100%"} />
            </CardContent>
          </>
        ),
        () => null,
        (employeeData: EmployeeSummary) => {
          const formerEmployee = isFormerEmployee(employeeData.leavingDate);
          return (
            <>
              <CardHeader
                avatar={
                  <UserAvatar
                    src={employeeData.avatar ?? undefined}
                    name={`${employeeData.firstName} ${employeeData.lastName}`}
                    size={size}
                    leavingDate={employeeData.leavingDate ?? undefined}
                  />
                }
                title={
                  <Typography
                    fontWeight={"bold"}
                    fontSize={fontSize}
                    color={formerEmployee ? "textSecondary" : undefined}
                  >
                    {`${employeeData.firstName} ${employeeData.lastName}`}
                  </Typography>
                }
                subheader={
                  findEmployeeField("jobTitle")?.options.visibility.showInEmployeeSummary ? (
                    <Typography fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
                      {employeeData.jobTitle}
                    </Typography>
                  ) : null
                }
              />
              <CardContent sx={{ py: 0, pb: (t) => `${t.spacing(1)}!important` }}>
                {employeeMetadataFields.map((field) =>
                  employeeData[field.fieldCode] &&
                  !SKIP_FIELD_CODE_IN_PROFILE_SUMMARY_ITEMS.includes(field.fieldCode) ? (
                    <ProfileItemInfo
                      key={field.fieldId}
                      value={employeeData[field.fieldCode]}
                      translation={field.translation}
                      fontSize={fontSize}
                      Icon={LabelImportantIcon}
                      // TODO Make this more robust when needed
                      action={field.fieldCode === "email" ? (a) => `mailto:${a}` : undefined}
                    />
                  ) : null
                )}
                {/* Phone is not an employee field, so adding this manually for now */}
                {/* Uncomment this when phone parsing logic is added
                {employeeData.phone && (
                  <ProfileItemInfo
                    value={employeeData.phone}
                    translation=""
                    fontSize={fontSize}
                    Icon={CallIcon}
                    action={(a) => `https://wa.me/${a}`}
                  />
                )} */}
              </CardContent>
            </>
          );
        }
      )}
    </ProfileSummaryLayout>
  );
};
