import useAuth from "@/hooks/useAuth";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";
import {
  clearEmployeeFieldsDefinitionCache,
  EmployeeField,
  getEmployeeFieldsDefinition,
} from "@/services/employeeService";
import { useQuery } from "@tanstack/react-query";
import { FC, createContext } from "react";

export type RefetchOptions = {
  breakCache?: boolean;
};

interface EmployeeFieldsContextValue {
  fields: EmployeeField[];
  loading: boolean;
  error: unknown | null;
  refetch: ({ breakCache }: RefetchOptions) => void;
}

export const EmployeeFieldsContext = createContext<EmployeeFieldsContextValue>({
  fields: [],
  loading: true,
  error: null,
  refetch: () => {},
});

export const EmployeeFieldsProvider: FC = ({ children }) => {
  const auth = useAuth();

  const {
    data: fields,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ["employeeFields", auth.organizationReference],
    queryFn: async () => {
      if (!auth.isAuthenticated || !auth.organizationReference) {
        return [];
      }

      try {
        return await getEmployeeFieldsDefinition(auth.organizationReference);
      } catch (err: unknown) {
        // Sentry out error
        console.error("[EMPLOYEE FIELDS] Error loading fields", err);
        throw err;
      }
    },
  });

  function refreshFields({ breakCache }: RefetchOptions): void {
    if (breakCache && auth.organizationReference) {
      clearEmployeeFieldsDefinitionCache(auth.organizationReference);
    }
    refetch();
  }

  return (
    <EmployeeFieldsContext.Provider value={{ fields: fields ?? [], loading, error, refetch: refreshFields }}>
      {auth.isAuthenticated && loading ? <LoadingScreen /> : children}
    </EmployeeFieldsContext.Provider>
  );
};
