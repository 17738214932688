import { dashboardAxios as axios } from "@/lib/axios";
import {
  TicketView,
  TicketListingView,
  TicketMetadata,
  TicketNoteView,
  TicketStatus,
  TicketType,
} from "@/types/common";
import { downloadReport, fetchReport, Locale, ReportType } from "@/services/reportService";
import { ListResult } from "@alanszp/core";

export async function searchTickets(
  filters: URLSearchParams,
  signal?: AbortSignal
): Promise<ListResult<TicketListingView>> {
  const response = ((await fetchReport(
    ReportType.LISTING_TICKETS,
    {
      filters,
      translate: false,
    },
    undefined,
    false,
    signal
  )) as {
    headers: Record<string, string>;
    data: TicketListingView[];
    page: number;
    pageSize: number;
    total: number;
    hasAnonymizedRows: boolean;
  }) ?? {
    headers: {},
    data: [],
    page: filters.get("page") ?? 1,
    pageSize: filters.get("pageSize") ?? 50,
    total: 0,
    hasAnonymizedRows: false,
  };

  return {
    elements: response.data,
    page: response.page,
    pageSize: response.pageSize,
    total: response.total,
  };
}

export async function downloadTicketReport(
  locale: string,
  format: "csv" | "xlsx",
  filters: URLSearchParams
): Promise<void> {
  return downloadReport(ReportType.LISTING_TICKETS, format, filters, undefined, locale as Locale);
}

export async function getTicket(ticketId: string): Promise<TicketView> {
  const response = await axios.get<TicketView>(`/v1/tickets/${ticketId}`);
  return response.data;
}

export async function getTicketTypes(): Promise<TicketType[]> {
  const response = await axios.get<ListResult<TicketType>>(`/v1/tickets/ticket-types`);
  return response.data.elements;
}

export interface UpdateTicketPayload {
  employeeReference?: string | null;
  body?: string | null;
  assignedTo?: string | null;
  metadata?: TicketMetadata;
  status?: TicketStatus;
  closedReason?: string | null;
  newNote?: string;
}

export async function updateTicket(ticketId: string, body: UpdateTicketPayload): Promise<TicketView> {
  const response = await axios.patch<TicketView>(`/v1/tickets/${ticketId}`, body);
  return response.data;
}

export interface TicketNoteParams {
  body: string;
}

export async function searchTicketNotes(ticketId: string): Promise<ListResult<TicketNoteView>> {
  const response = await axios.get<ListResult<TicketNoteView>>(`/v1/tickets/${ticketId}/notes`);
  return response.data;
}

export async function createTicketNote(ticketId: string, params: TicketNoteParams): Promise<TicketNoteView> {
  const response = await axios.post<TicketNoteView>(`/v1/tickets/${ticketId}/notes`, params);
  return response.data;
}

export async function updateTicketNote(
  ticketId: string,
  noteId: string,
  params: TicketNoteParams
): Promise<TicketNoteView> {
  const response = await axios.patch<TicketNoteView>(`/v1/tickets/${ticketId}/notes/${noteId}`, params);
  return response.data;
}

export async function deleteTicketNote(ticketId: string, noteId: string): Promise<TicketNoteView> {
  const response = await axios.delete<TicketNoteView>(`/v1/tickets/${ticketId}/notes/${noteId}`);
  return response.data;
}
