import { dashboardAxios as axios, dashboardAxios } from "@/lib/axios";
import {
  DefaultRoles,
  FileIntegration,
  GSheetIntegration,
  LoginPolicyConfiguration,
  OffboardingCohorts,
  Organization,
  OrganizationMetrics,
  OrganizationSchedulingConfig,
  SlackIntegration,
  TranslationLocales,
} from "@/types/common";

let cache: OrganizationMetrics | undefined;

export async function getOrganizationMetrics(): Promise<OrganizationMetrics> {
  if (!cache) {
    const response = await axios.get<OrganizationMetrics>("/v1/dashboards/home/organization/metrics");
    cache = response.data;
  }

  return cache;
}

export async function getOrganization(): Promise<Organization> {
  const response = await axios.get<Organization[]>("/v1/organizations");
  const organizationReference = window.localStorage.getItem("org") || undefined;
  const organization = response.data.find((o) => o.code === organizationReference);
  return organization as Organization;
}

export async function getMyOrganization(): Promise<Organization> {
  const response = await dashboardAxios.get<Organization>(`/v1/organizations/me`);
  return response.data;
}

export async function getOrganizationLoginPolicy(): Promise<LoginPolicyConfiguration> {
  const response = await dashboardAxios.get<LoginPolicyConfiguration>(`/v1/organization/login-policy`);
  return response.data;
}

export async function getSlackIntegration(integrationId: string): Promise<SlackIntegration> {
  const response = await axios.get<{ integration: SlackIntegration }>(`/v1/integrations/slack/${integrationId}`);

  return response.data.integration;
}

export async function getGSheetIntegration(integrationId: string): Promise<GSheetIntegration> {
  const response = await axios.get<GSheetIntegration>(`/v1/integrations/gsheet/${integrationId}`);
  return response.data;
}

export async function getFileIntegration(integrationId: string): Promise<FileIntegration> {
  const response = await axios.get<FileIntegration>(`/v1/integrations/file/${integrationId}`);
  return response.data;
}

export interface UpdateOrganizationBody {
  name?: string;
  schedulingConfig?: OrganizationSchedulingConfig;
  loginPolicy?: LoginPolicyConfiguration;
  offboardingCohorts?: OffboardingCohorts;
  defaultRoles?: DefaultRoles;
}
export async function updateOrganization(body: UpdateOrganizationBody): Promise<Organization> {
  const response = await axios.patch<Organization>(`/v1/organization`, body);
  return response.data;
}

export async function getOrganizationLocales(): Promise<TranslationLocales[]> {
  const response = await dashboardAxios.get<{ locales: TranslationLocales[] }>("/v1/translations/locales");
  return response.data.locales;
}
