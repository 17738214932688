import { relativeDateRegex } from "@/components/advanced-filters/utils";
import { newDate } from "@/utils/newDate";
import {
  add,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
} from "date-fns";
import { now } from "lodash";

type Operator = "+" | "-";
type TimeUnit =
  | "d"
  | "day"
  | "days"
  | "w"
  | "week"
  | "weeks"
  | "m"
  | "month"
  | "months"
  | "q"
  | "quarter"
  | "quarters"
  | "y"
  | "year"
  | "years";

type PeriodModifierExtreme = "s" | "e";
type PeriodModifierTimeUnit = "w" | "m" | "q" | "y";

// Will modify now or the relative date, and move date to an extreme of the time unit defined.
// ej: -2d:eoy --> will calculate -2d and to the resulting date, will change the date & month to XXXX-12-31
type PeriodModifier = `${PeriodModifierExtreme}o${PeriodModifierTimeUnit}`;

type RelativeDateWithoutModifiers = `${Operator}${number}${TimeUnit}` | "now";

type RelativeDate = `${RelativeDateWithoutModifiers}` | `${RelativeDateWithoutModifiers}:${PeriodModifier}`;

const INPUT_DATE_FORMAT = "yyyy-MM-dd" as const;

const RelativeDateUnit = {
  DAYS: "days",
  WEEKS: "weeks",
  MONTHS: "months",
  YEARS: "years",
  NOW: "now",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type RelativeDateUnit = (typeof RelativeDateUnit)[keyof typeof RelativeDateUnit];

const PLUS = "+";

type TimeUnitAndQuantity = { unit: TimeUnit; quantity: number };

function defaultRelativeDateConversor(unit: TimeUnit): (quantity: number) => TimeUnitAndQuantity {
  return (quantity: number) => ({ unit, quantity });
}

const MODIFIER_FN_MAP: Record<PeriodModifierExtreme, Record<PeriodModifierTimeUnit, (date: Date) => Date>> = {
  e: {
    w: endOfWeek,
    m: endOfMonth,
    q: endOfQuarter,
    y: endOfYear,
  },
  s: {
    w: startOfWeek,
    m: startOfMonth,
    q: startOfQuarter,
    y: startOfYear,
  },
};

function relativeDateMap(): Record<TimeUnit, (q: number) => TimeUnitAndQuantity> {
  return {
    d: defaultRelativeDateConversor(RelativeDateUnit.DAYS),
    day: defaultRelativeDateConversor(RelativeDateUnit.DAYS),
    days: defaultRelativeDateConversor(RelativeDateUnit.DAYS),
    w: defaultRelativeDateConversor(RelativeDateUnit.WEEKS),
    week: defaultRelativeDateConversor(RelativeDateUnit.WEEKS),
    weeks: defaultRelativeDateConversor(RelativeDateUnit.WEEKS),
    m: defaultRelativeDateConversor(RelativeDateUnit.MONTHS),
    month: defaultRelativeDateConversor(RelativeDateUnit.MONTHS),
    months: defaultRelativeDateConversor(RelativeDateUnit.MONTHS),
    q: (q: number) => ({ unit: RelativeDateUnit.MONTHS, quantity: q * 3 }),
    quarter: (q: number) => ({ unit: RelativeDateUnit.MONTHS, quantity: q * 3 }),
    quarters: (q: number) => ({ unit: RelativeDateUnit.MONTHS, quantity: q * 3 }),
    y: defaultRelativeDateConversor(RelativeDateUnit.YEARS),
    year: defaultRelativeDateConversor(RelativeDateUnit.YEARS),
    years: defaultRelativeDateConversor(RelativeDateUnit.YEARS),
  };
}

export function parseRelativeDate(
  fullRelative: string,
  operator: string,
  rawQuantity: TimeUnit,
  rawTimeUnit: string
): Date {
  if (fullRelative === RelativeDateUnit.NOW) {
    return new Date();
  }

  const op = operator === PLUS ? add : sub;
  const { unit, quantity } = relativeDateMap()[rawTimeUnit as TimeUnit](Number.parseInt(rawQuantity, 10));
  return op(now(), { [unit]: quantity });
}

function isRelativeDate(raw: string): raw is RelativeDate {
  return relativeDateRegex.test(raw);
}

function modifyDate(date: Date, extreme: string, timeUnit: string): Date {
  return MODIFIER_FN_MAP[extreme as PeriodModifierExtreme]?.[timeUnit as PeriodModifierTimeUnit]?.(date) ?? date;
}

export function parseDate(raw: string): Date {
  if (!isRelativeDate(raw)) {
    return newDate(raw);
  }

  const result = relativeDateRegex.exec(raw);
  if (!result) {
    throw new Error("Relative date error parsing");
  }

  const [, fullRelative, operator, rawQuantity, rawTimeUnit, modifierExtreme, modifierTimeUnit] = result;

  const relativeDate = parseRelativeDate(fullRelative, operator, rawQuantity as TimeUnit, rawTimeUnit);

  if (!modifierExtreme || !modifierTimeUnit) {
    return relativeDate;
  }

  return modifyDate(relativeDate, modifierExtreme, modifierTimeUnit);
}

export function parseDateToString(date: string): string {
  return format(parseDate(date), INPUT_DATE_FORMAT);
}
