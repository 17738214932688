import { ComponentProps, FC, LazyExoticComponent, Suspense, lazy } from "react";
import { Outlet, RouteObject } from "react-router";
import AuthGuard from "@/modules/skeleton/guards/AuthGuard";
import DashboardLayout from "@/modules/skeleton/layouts/DashboardLayout";
import GuestGuard from "@/modules/skeleton/guards/GuestGuard";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";
import EmployeeLayout from "@/modules/employee/layouts/EmployeeDetailLayout";
import { Navigate } from "react-router-dom";
import { RoleGuard } from "@/modules/skeleton/guards/RoleGuard";
import AssetLoadErrorBoundary from "@/components/AssetLoadErrorBoundary";
import { SplitTreatment } from "@/hooks/useSplitTreatment";
import { TreatmentGuard } from "@/modules/skeleton/guards/TreatmentGuard";
import CreateTeamPage from "@/modules/team/pages/CreateTeamPage";
import TeamsLayoutPage from "@/modules/teams/pages/TeamsLayoutPage";
import { OldRoleCode } from "@/types/user";
import { BoardListSidebar } from "@/modules/board/components/BoardListSidebar";
import PartialAuthGuard from "@/modules/skeleton/guards/PartialAuthGuard";
import { PermissionGuard } from "@/modules/skeleton/guards/PermissionGuard";
import { CommunicationsPermissions, FeedbackTypeListPermissions } from "@/types/common";
import { PermissionCode } from "@/types/PermissionCode";
import { HelpdeskPageTestContainer } from "@/modules/helpdesk/components/HelpdeskPageTestContainer";
import HelpdeskPageTest from "@/modules/helpdesk/pages/HelpdeskPageTest";
import PromptSectionCreatePage from "@/modules/settings/pages/PromptSectionCreatePage";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: ComponentProps<LazyExoticComponent<FC>>) => (
  <AssetLoadErrorBoundary>
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  </AssetLoadErrorBoundary>
);

// Authentication pages

const Login = Loadable(lazy(() => import("@/modules/authentication/pages/Login")));
const ForgotPassword = Loadable(lazy(() => import("@/modules/authentication/pages/ForgotPassword")));
const ChangePassword = Loadable(lazy(() => import("@/modules/authentication/pages/ChangePassword")));
const PasswordReset = Loadable(lazy(() => import("@/modules/authentication/pages/PasswordReset")));
const MfaSetup = Loadable(lazy(() => import("@/modules/authentication/pages/MfaSetup")));
const MfaChallenge = Loadable(lazy(() => import("@/modules/authentication/pages/MfaChallenge")));

// Manager view pages

const MyTeamPage = Loadable(lazy(() => import("@/modules/team/pages/MyTeamPage")));
const EditTeamPage = Loadable(lazy(() => import("@/modules/team/pages/EditTeamPage")));
const CompareTeamPage = Loadable(lazy(() => import("@/modules/team/pages/CompareTeamPage")));

// Dashboard pages

const NotFound = Loadable(lazy(() => import("@/pages/NotFound")));
const Settings = Loadable(lazy(() => import("@/modules/settings/pages/Settings")));
const SlackOAuthPage = Loadable(lazy(() => import("@/modules/settings/pages/SlackOAuthPage")));
const GChatFinishSetup = Loadable(lazy(() => import("@/modules/settings/components/GChatFinishSetup")));
const TeamsFinishSetup = Loadable(lazy(() => import("@/modules/settings/components/TeamsFinishSetup")));

// Tickets
const TicketListPage = Loadable(lazy(() => import("@/modules/tickets/pages/TicketListPage")));
const TicketDetailPage = Loadable(lazy(() => import("@/modules/tickets/pages/TicketDetailPage")));

// CaseManagement
const CaseManagementListPage = Loadable(lazy(() => import("@/modules/case_management/pages/CaseManagementListPage")));
const CaseDetailPage = Loadable(lazy(() => import("@/modules/case_management/pages/CaseDetailPage")));
const CaseCreatePage = Loadable(lazy(() => import("@/modules/case_management/pages/CaseCreatePage")));

const HomePage = Loadable(lazy(() => import("@/modules/home/pages/HomePage")));

const EmployeeListPage = Loadable(lazy(() => import("@/modules/employee/pages/EmployeeListPage")));
const EmployeeDetailTeamPage = Loadable(lazy(() => import("@/modules/employee/pages/EmployeeDetailTeamPage")));
const EmployeeDetailHistoryPage = Loadable(lazy(() => import("@/modules/employee/pages/EmployeeDetailHistoryPage")));
const EmployeeDetailOrganizationPage = Loadable(
  lazy(() => import("@/modules/employee/pages/EmployeeDetailOrganizationPage"))
);
const EmployeeDetailAuditPage = Loadable(lazy(() => import("@/modules/employee/pages/EmployeeDetailAuditPage")));

const QuestionsListPage = Loadable(lazy(() => import("@/modules/questions/pages/QuestionsListPage")));
const DetailQuestionPage = Loadable(lazy(() => import("@/modules/questions/pages/DetailQuestionPage")));

const FeedbackList = Loadable(lazy(() => import("@/modules/feedback/pages/FeedbackList")));

// Teams
const TeamsListPage = Loadable(lazy(() => import("@/modules/teams/pages/TeamsListPage")));
const CustomTeamsListPage = Loadable(lazy(() => import("@/modules/teams/pages/CustomTeamsListPage")));
const TeamChangeManagementListPage = Loadable(lazy(() => import("@/modules/teams/pages/TeamChangeManagementListPage")));
const TeamEditPage = Loadable(lazy(() => import("@/modules/teams/pages/TeamEditPage")));
const TeamCreatePage = Loadable(lazy(() => import("@/modules/teams/pages/TeamCreatePage")));
const TeamDetailPage = Loadable(lazy(() => import("@/modules/teams/pages/TeamDetailPage")));

// Reporting pages
const ReportingListPage = Loadable(lazy(() => import("@/modules/reporting/pages/ReportingListPage")));
const ReportingDetailPage = Loadable(lazy(() => import("@/modules/reporting/pages/ReportingDetailPage")));

// Faqs
const FaqsPage = Loadable(lazy(() => import("@/modules/faqs/pages/FaqsPage")));

// Helpdesk
const HelpdeskListPage = Loadable(lazy(() => import("@/modules/helpdesk/pages/HelpdeskListPage")));
const HelpdeskEditPage = Loadable(lazy(() => import("@/modules/helpdesk/pages/HelpdeskEditPage")));
const HelpdeskCreatePage = Loadable(lazy(() => import("@/modules/helpdesk/pages/HelpdeskCreatePage")));
const CreateHelpdeskItemsBulkPage = Loadable(
  lazy(() => import("@/modules/helpdesk/pages/CreateHelpdeskItemsBulkPage"))
);

// Custom Dashboard
const BoardListPage = Loadable(lazy(() => import("@/modules/board/pages/BoardListPage")));
const BoardDetailPage = Loadable(lazy(() => import("@/modules/board/pages/BoardDetailPage")));
const BoardFolderPage = Loadable(lazy(() => import("@/modules/board/pages/BoardListFolderPage")));
const WidgetBuilderPage = Loadable(lazy(() => import("@/modules/board/pages/WidgetBuilderPage")));

// Communications

const CommunicationsCreatePage = Loadable(
  lazy(() => import("@/modules/communications/pages/CommunicationsCreatePage"))
);
const UpdateScheduledRule = Loadable(lazy(() => import("@/modules/communications/pages/UpdateScheduledRule")));
const CommunicationsTemplatesPage = Loadable(
  lazy(() => import("@/modules/communications/pages/CommunicationsTemplatesPage"))
);
const CommunicationTemplateCreatePage = Loadable(
  lazy(() => import("@/modules/communications/pages/CommunicationTemplateCreatePage"))
);

const CommunicationTemplateUpdatePage = Loadable(
  lazy(() => import("@/modules/communications/pages/CommunicationTemplateUpdatePage"))
);

const CommunicationsResultsPage = Loadable(
  lazy(() => import("@/modules/communications/pages/CommunicationsResultsPage"))
);

const routes: RouteObject[] = [
  // Guest routes
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <GuestGuard>
            <ForgotPassword />
          </GuestGuard>
        ),
      },
      {
        path: "mfa-setup",
        element: (
          <PartialAuthGuard>
            <MfaSetup />
          </PartialAuthGuard>
        ),
      },
      {
        path: "mfa-challenge",
        element: (
          <PartialAuthGuard>
            <MfaChallenge />
          </PartialAuthGuard>
        ),
      },
      {
        path: "change-password/:token",
        element: (
          <GuestGuard>
            <ChangePassword />
          </GuestGuard>
        ),
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
    ],
  },
  // Authenticated routes
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "team",
        children: [
          {
            path: ":teamId",
            element: (
              <TreatmentGuard treatments={[SplitTreatment.EDIT_TEAMS]}>
                <RoleGuard roles={[OldRoleCode.MANAGER]} />
              </TreatmentGuard>
            ),
            children: [
              {
                path: "edit",
                element: <EditTeamPage />,
              },
            ],
          },
        ],
      },
      {
        // manager
        path: "my",
        children: [
          {
            path: "team-insights",
            element: (
              <PermissionGuard strategy="single" code={PermissionCode.FRONT_SECTION_MANAGER_MY_TEAM_VIEW}>
                <Outlet />
              </PermissionGuard>
            ),
            children: [
              {
                path: "",
                element: <MyTeamPage />,
              },
              {
                path: "create",
                element: <CreateTeamPage />,
              },
              {
                path: ":teamId",
                element: <MyTeamPage />,
              },
            ],
          },
          {
            path: "comparison",
            element: (
              <PermissionGuard strategy="single" code={PermissionCode.FRONT_SECTION_MANAGER_TEAM_COMPARISON_VIEW}>
                <CompareTeamPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: "/prompts",
        children: [{ path: "create", element: <PromptSectionCreatePage /> }],
      },
      {
        path: "/settings",
        children: [
          { path: "", element: <Navigate to="/settings/user" replace /> },
          { path: ":tab", element: <Settings /> },
          { path: ":tab/:action", element: <Settings /> },
          { path: ":tab/:action/:entityId", element: <Settings /> },
          {
            path: "teams",
            element: (
              <Navigate
                to={{
                  pathname: "settings/teams/custom",
                }}
              />
            ),
          },
        ],
      },
      {
        path: "",
        element: <RoleGuard roles={[OldRoleCode.ADMIN, OldRoleCode.HRBP, OldRoleCode.INTEGRATIONS]} />,
        children: [
          {
            path: "slack/oauth/redirect",
            element: <SlackOAuthPage />,
          },
          {
            path: "gchat/setup/redirect",
            element: <GChatFinishSetup />,
          },
          {
            path: "teams/setup/redirect",
            element: <TeamsFinishSetup />,
          },
        ],
      },
      {
        path: "",
        children: [
          // ALL MODULES
          {
            path: "employees",
            element: <PermissionGuard code={PermissionCode.FRONT_SECTION_EMPLOYEES_VIEW} />,
            children: [
              {
                path: "",
                element: <EmployeeListPage />,
              },
              {
                path: ":employeeId",
                element: <EmployeeLayout />,
                children: [
                  {
                    path: "",
                    element: <EmployeeDetailHistoryPage />,
                  },
                  {
                    path: "org",
                    element: <EmployeeDetailOrganizationPage />,
                  },
                  {
                    path: "team",
                    element: (
                      <PermissionGuard code={PermissionCode.FRONT_SECTION_TEAMS_METRICS_VIEW}>
                        <EmployeeDetailTeamPage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: "team/:teamId",
                    element: (
                      <PermissionGuard code={PermissionCode.FRONT_SECTION_TEAMS_METRICS_VIEW}>
                        <EmployeeDetailTeamPage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: "audit",
                    element: <EmployeeDetailAuditPage />,
                  },
                ],
              },
            ],
          },
          // FAQS MODULE
          {
            path: "",
            element: (
              <TreatmentGuard treatments={[SplitTreatment.FAQS_ACTIVATED]}>
                <Outlet />
              </TreatmentGuard>
            ),
            children: [
              {
                path: "faqs",
                element: <Navigate to={{ pathname: "/helpdesk/metrics" }} />,
              },
            ],
          },
          // HELPDESK MODULE
          {
            path: "helpdesk",
            element: (
              <TreatmentGuard treatments={[SplitTreatment.FAQS_ACTIVATED]}>
                <Outlet />
              </TreatmentGuard>
            ),
            children: [
              {
                path: "metrics",
                element: (
                  <PermissionGuard
                    strategy="some"
                    code={[
                      "reporting.metric.faqsByMenu:query",
                      "reporting.metric.faqsUseful:query",
                      "reporting.metric.totalFaqsQueries:query",
                    ]}
                  >
                    <FaqsPage />
                  </PermissionGuard>
                ),
              },
              {
                path: "knowledge",
                element: (
                  <PermissionGuard strategy="every" code={["helpdesk.knowledge:list"]}>
                    <HelpdeskListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: "knowledge/:knowledgeDocumentId/edit",
                element: (
                  <PermissionGuard strategy="every" code={["helpdesk.knowledge:edit"]}>
                    <HelpdeskEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: "knowledge/create",
                element: (
                  <PermissionGuard strategy="single" code={"helpdesk.knowledge:create"}>
                    <HelpdeskCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: "knowledge/create/bulk",
                element: (
                  <PermissionGuard strategy="single" code={"helpdesk.knowledge:create"}>
                    <CreateHelpdeskItemsBulkPage />
                  </PermissionGuard>
                ),
              },
              {
                path: "knowledge/test",
                element: <HelpdeskPageTest />,
              },
            ],
          },
          // EXPERIENCE MODULE
          {
            path: "",
            element: (
              <TreatmentGuard treatments={[SplitTreatment.EXPERIENCE_MODULE_ACTIVATED]}>
                <Outlet />
              </TreatmentGuard>
            ),
            children: [
              {
                path: "cases",
                element: <RoleGuard roles={[OldRoleCode.ADMIN, OldRoleCode.HRBP]} />,
                children: [
                  {
                    path: "",
                    element: (
                      <PermissionGuard strategy="single" code={PermissionCode.CASE_LIST}>
                        <CaseManagementListPage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: "new",
                    element: (
                      <PermissionGuard strategy="single" code={PermissionCode.CASE_CREATE}>
                        <CaseCreatePage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: ":caseId",
                    element: (
                      <PermissionGuard strategy="single" code={PermissionCode.CASE_LIST}>
                        <CaseDetailPage />
                      </PermissionGuard>
                    ),
                  },
                ],
              },
              {
                path: "tickets",
                element: <PermissionGuard strategy="single" code={PermissionCode.TICKET_ACCESS} />,
                children: [
                  {
                    path: "",
                    element: <TicketListPage />,
                  },
                  {
                    path: ":ticketId",
                    element: <TicketDetailPage />,
                  },
                ],
              },
              {
                path: "teams",
                element: (
                  <RoleGuard roles={[OldRoleCode.ADMIN, OldRoleCode.HRBP]}>
                    <PermissionGuard strategy="single" code={PermissionCode.FRONT_SECTION_TEAMS_VIEW}>
                      <TeamsLayoutPage>
                        <Outlet />
                      </TeamsLayoutPage>
                    </PermissionGuard>
                  </RoleGuard>
                ),
                children: [
                  {
                    path: "",
                    element: <TeamsListPage />,
                  },
                  {
                    path: "custom",
                    element: <CustomTeamsListPage />,
                  },
                  {
                    path: "pending-changes",
                    element: <TeamChangeManagementListPage />,
                  },
                  {
                    path: "metrics/:teamId",
                    element: (
                      <PermissionGuard
                        strategy="every"
                        code={[
                          PermissionCode.FRONT_SECTION_TEAMS_VIEW,
                          PermissionCode.FRONT_SECTION_TEAMS_METRICS_VIEW,
                        ]}
                      >
                        <TeamDetailPage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: "edit/:entityId",
                    element: <TeamEditPage />,
                  },
                  {
                    path: "create",
                    element: <TeamCreatePage />,
                  },
                ],
              },
              {
                path: "questions",
                children: [
                  {
                    path: "",
                    element: (
                      <PermissionGuard strategy="single" code={PermissionCode.REPORTING_QUERY_METRIC_QUESTION_SUMMARY}>
                        <QuestionsListPage />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: ":questionId",
                    element: (
                      <PermissionGuard strategy="single" code={PermissionCode.QUESTION_VIEW}>
                        <DetailQuestionPage />
                      </PermissionGuard>
                    ),
                  },
                ],
              },
              /**
               * @deprecated
               * This routes are deprecated and should be removed in the future
               * They are kept here for backward compatibility with old links
               * They should redirect to the new board module that encapsulates all the old modules
               */
              {
                path: "enps",
                element: <Navigate to={{ pathname: "/board" }} />,
              },
              {
                path: "mood",
                element: <Navigate to={{ pathname: "/board" }} />,
              },
              {
                path: "drivers",
                element: <Navigate to={{ pathname: "/board" }} />,
              },
            ],
          },
          // REPORTING MODULE
          {
            path: "reporting",
            children: [
              {
                path: "",
                element: (
                  <PermissionGuard code={PermissionCode.FRONT_SECTION_REPORTING_VIEW} strategy="single">
                    <ReportingListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ":reportType",
                element: (
                  <PermissionGuard code={PermissionCode.FRONT_SECTION_REPORTING_VIEW} strategy="single">
                    <ReportingDetailPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          // FEEDBACK MODULE
          {
            path: "feedback",
            element: (
              <TreatmentGuard
                treatments={[SplitTreatment.EXPERIENCE_MODULE_ACTIVATED, SplitTreatment.FEEDBACK_ENABLED]}
              >
                <Outlet />
              </TreatmentGuard>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="/feedback/all/1" replace />,
              },
              {
                path: ":feedbackType/:page",
                children: [
                  {
                    path: "",
                    element: (
                      <PermissionGuard code={FeedbackTypeListPermissions} strategy="some">
                        <FeedbackList />
                      </PermissionGuard>
                    ),
                  },
                  {
                    path: ":feedbackId",
                    element: (
                      <PermissionGuard code={FeedbackTypeListPermissions} strategy="some">
                        <FeedbackList />
                      </PermissionGuard>
                    ),
                  },
                ],
              },
            ],
          },
          // BOARDS MODULE
          {
            path: "board",
            element: <PermissionGuard strategy="single" code={PermissionCode.FRONT_SECTION_BOARDS_VIEW} />,
            children: [
              {
                path: "",
                element: <BoardListSidebar />,
                children: [
                  {
                    path: "",
                    element: <BoardListPage />,
                  },
                  {
                    path: "folder/:folderId",
                    children: [
                      {
                        path: "",
                        element: <BoardFolderPage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: ":boardId",
                children: [
                  {
                    path: "",
                    element: <BoardDetailPage />,
                  },
                  {
                    path: "widget-builder",
                    children: [
                      {
                        path: "",
                        element: <WidgetBuilderPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/communications",

        children: [
          {
            path: "create",
            element: (
              <PermissionGuard code={CommunicationsPermissions.CREATE_ONETIME} strategy="single">
                <CommunicationsCreatePage />,
              </PermissionGuard>
            ),
          },
          {
            path: "update/:scheduledRuleId",
            element: <UpdateScheduledRule />,
          },
          {
            path: "results",
            element: (
              <PermissionGuard code={CommunicationsPermissions.COMMUNICATIONS_SENDING_LIST} strategy="single">
                <CommunicationsResultsPage />,
              </PermissionGuard>
            ),
          },
          {
            path: "templates",
            children: [
              {
                path: "",
                element: (
                  <PermissionGuard code={CommunicationsPermissions.TEMPLATE_LIST} strategy="single">
                    <CommunicationsTemplatesPage />
                  </PermissionGuard>
                ),
              },
              {
                path: "create",
                element: (
                  <PermissionGuard code={CommunicationsPermissions.TEMPLATE_CREATE} strategy="single">
                    <CommunicationTemplateCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ":templateId",
                element: (
                  <PermissionGuard code={CommunicationsPermissions.TEMPLATE_MODIFY} strategy="single">
                    <CommunicationTemplateUpdatePage />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
