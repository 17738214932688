import { type FC } from "react";
import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { SplitFactory } from "@splitsoftware/splitio-react";

import "./translations/i18n";
import GlobalStyles from "./modules/skeleton/components/GlobalStyles";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";

import routes from "./routing/routes";
import { createTheme } from "./theme/theme";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDateFns } from "./hooks/useDateFns";
import { splitFactory } from "./lib/split";
import { useCurrentLanguage } from "@/hooks/useCurrentLanguage";
import { Lang } from "@/translations/AvailableLanguages";
import { DriversProvider } from "./contexts/DriversContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleSSOConfig } from "./config";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { EmployeeFieldsProvider } from "@/contexts/EmployeeFieldsContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { OnlyLaraUserWrapper } from "./components/OnlyLaraUserWrapper";
import { CommandMenu } from "./components/CommandMenu";
import { DevRevPlug } from "@/components/DevRevPlug";
import { HelmetProvider } from "react-helmet-async";
import SecurityHeaders from "./components/SecurityHeaders";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";

type AppProps = {
  pca: IPublicClientApplication;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC<AppProps> = ({ pca }) => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const { i18n } = useTranslation();
  const { locale } = useDateFns();
  const language = useCurrentLanguage();

  useScrollReset();
  const theme = createTheme(
    {
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: settings.theme,
    },
    language as Lang
  );

  return (
    <HelmetProvider>
      <SecurityHeaders />
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
              <MsalProvider instance={pca}>
                <GoogleOAuthProvider clientId={googleSSOConfig}>
                  <SnackbarProvider dense maxSnack={3} preventDuplicate>
                    <GlobalStyles />
                    {auth.isInitialized && i18n.isInitialized ? (
                      <>
                        <DriversProvider>
                          <EmployeeFieldsProvider>
                            {auth.user?.organizationReference ? (
                              <SplitFactory factory={splitFactory(auth.user.organizationReference)}>
                                <>
                                  {content}
                                  <OnlyLaraUserWrapper>
                                    <CommandMenu />
                                  </OnlyLaraUserWrapper>
                                  <DevRevPlug />
                                </>
                              </SplitFactory>
                            ) : (
                              content
                            )}
                          </EmployeeFieldsProvider>
                        </DriversProvider>
                      </>
                    ) : (
                      <LoadingScreen />
                    )}
                  </SnackbarProvider>
                </GoogleOAuthProvider>
              </MsalProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
