import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import HelpIcon from "@mui/icons-material/Help";
import useAuth from "@/hooks/useAuth";
import GroupsIcon from "@mui/icons-material/Groups";
import PublicIcon from "@mui/icons-material/Public";
import { FilterConfiguration } from "@/components/advanced-filters/FilterConfiguration";
import { ViewOrgScope, useViewOrgScope } from "@/modules/reporting/components/ViewOrgScope";

export enum ViewOrgDataScope {
  ALL_ORG = "allOrg",
  MY_EMPLOYEES = "myEmployees",
}

type Props = {
  value: ViewOrgDataScope;
  handleChangeValue: (value: ViewOrgDataScope) => void;
};

export const BoardSwitchScope: FC<Props> = ({ value, handleChangeValue }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const viewOrgScope = useViewOrgScope();

  if (viewOrgScope === ViewOrgScope.NOTHING) {
    return null;
  }

  const noSegmentApplied = auth.user?.segmentReference === null;

  const booleanValue = value === ViewOrgDataScope.ALL_ORG;

  const viewAll = booleanValue || noSegmentApplied;

  const handleOnClick = () =>
    handleChangeValue(value === ViewOrgDataScope.ALL_ORG ? ViewOrgDataScope.MY_EMPLOYEES : ViewOrgDataScope.ALL_ORG);

  const icon = viewAll ? <PublicIcon sx={{ ml: 1 }} /> : <GroupsIcon />;
  const label = viewAll
    ? t("board.detail.viewOrgScope.viewOrg.switch")
    : t("board.detail.viewOrgScope.myEmployees.switch");
  const tooltip = noSegmentApplied
    ? t("board.detail.viewOrgScope.viewOrg.tooltipAdmin")
    : booleanValue
      ? t("board.detail.viewOrgScope.viewOrg.tooltip")
      : t("board.detail.viewOrgScope.myEmployees.tooltip");

  return (
    <Chip
      icon={icon}
      // Add padding if GroupsIcon is shown to avoid consistency issues
      sx={{ pl: viewAll ? 0 : 1 }}
      color="primary"
      variant="outlined"
      label={
        <Tooltip title={tooltip}>
          <Box display="flex">
            <Typography variant={"body2"}>{label}</Typography>
            <HelpIcon color="inherit" fontSize="small" sx={{ ml: 1 }} />
          </Box>
        </Tooltip>
      }
      clickable={!noSegmentApplied}
      onClick={!noSegmentApplied ? handleOnClick : undefined}
    />
  );
};

export const overrideActiveFilterComponentForViewOrgScope: FilterConfiguration["overrideActiveFilterComponent"] = (
  state,
  _filter,
  _operator,
  value: ViewOrgDataScope
) => {
  const toggleFilterValue = (newValue: ViewOrgDataScope) => {
    state.setValue("viewOrgData", {
      ...state.values.viewOrgData,
      value: newValue,
    });
  };

  return <BoardSwitchScope value={value} handleChangeValue={toggleFilterValue} />;
};
