import { Box, Link, Typography } from "@mui/material";
import UserAvatar from "../UserAvatar";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import isFormerEmployee from "@/utils/isFormerEmployee";
import { AvatarSize } from "@/types/common";
import { FC, useRef } from "react";
import { ProfileSummary } from "./ProfileSummary";
import PopupState, { bindHover } from "material-ui-popup-state";

export interface User {
  employeeReference?: string | null;
  name?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
}

export interface ProfileAvatarProps {
  user: User;
  size?: AvatarSize;
  link?: string;
  hideAvatar?: boolean;
  engagementScore?: number;
  hideName?: boolean;
  hideArrow?: boolean;
  principalManager?: string;
  profileSummary?: boolean;
  leavingDate?: string;
  subText?: string | JSX.Element;
  mr?: number;
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  user,
  link,
  engagementScore,
  size = AvatarSize.NORMAL,
  hideName = false,
  hideArrow = false,
  profileSummary = false,
  hideAvatar = false,
  principalManager,
  leavingDate,
  subText,
  mr = 1,
}) => {
  const { t } = useTranslation();
  const anchorEl = useRef<HTMLElement>();

  const fontSize = size === AvatarSize.SMALL ? 12 : 14;

  const formerEmployee = isFormerEmployee(leavingDate);

  const avatarAndText = (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "hidden",
      }}
      ref={anchorEl}
    >
      {!hideAvatar && (
        <Box>
          <UserAvatar
            size={size}
            src={user.avatar}
            name={user.name || `${user.firstName} ${user.lastName}`}
            sx={{ mr }}
            engagementScore={engagementScore}
            nameOnTooltip={hideName && !profileSummary}
            leavingDate={leavingDate}
          />
        </Box>
      )}
      {!hideName && (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontWeight={"bold"} fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
              {user.name || `${user.firstName} ${user.lastName}`}
            </Typography>
            {!hideArrow && (
              <ArrowForwardIcon sx={{ ml: 0.1, fontSize: "13px" }} color={formerEmployee ? "disabled" : "inherit"} />
            )}
          </Box>

          {user.jobTitle && (
            <Typography fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
              {user.jobTitle}
            </Typography>
          )}
          {principalManager && (
            <Typography fontSize={10}>
              {t("common.userRole.role_manager")}: {principalManager}
            </Typography>
          )}

          {subText && <Typography fontSize={10}>{subText}</Typography>}
        </Box>
      )}
    </Box>
  );

  return (
    <PopupState variant="popper" popupId="profile-summary">
      {(popupState) => (
        <div>
          <Box {...bindHover(popupState)}>
            {link ? (
              <Link color="inherit" underline="hover" to={link} component={RouterLink}>
                {avatarAndText}
              </Link>
            ) : (
              avatarAndText
            )}
          </Box>

          {profileSummary && user.employeeReference && (
            <ProfileSummary
              popupState={popupState}
              anchorEl={anchorEl}
              employeeReference={user.employeeReference}
              size={size}
            />
          )}
        </div>
      )}
    </PopupState>
  );
};

export const ProfileAvatarAnonymous: FC<{
  size?: AvatarSize;
  hideName?: boolean;
  subText?: string | JSX.Element;
  hideAvatar?: boolean;
}> = ({ hideName = false, hideAvatar = false, subText, size = AvatarSize.NORMAL }) => {
  const { t } = useTranslation();
  const fontSize = size === AvatarSize.SMALL ? 12 : 14;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "hidden",
      }}
    >
      {!hideAvatar && (
        <Box>
          <UserAvatar size={size} src={"/static/icons/user.svg"} name={t("feedback.anonymous")} sx={{ mr: 1 }} />
        </Box>
      )}
      {!hideName && (
        <Box>
          <Typography fontWeight={"bold"} fontSize={fontSize}>
            {t("feedback.anonymous")}
          </Typography>
          {subText && <Typography fontSize={10}>{subText}</Typography>}
        </Box>
      )}
    </Box>
  );
};
