import { HelpdeskMenuBaseItem } from "@/services/faqsService";
import { deburr } from "lodash";

export const updateNodeDescription = (
  nodes: HelpdeskMenuBaseItem[],
  nodeId: string,
  newDescription: string
): HelpdeskMenuBaseItem[] => {
  return nodes.map((node) => {
    if (node.id === nodeId) {
      return { ...node, description: newDescription };
    }
    if (node.children?.length) {
      return {
        ...node,
        children: updateNodeDescription(node.children, nodeId, newDescription),
      };
    }
    return node;
  });
};

export const updateNodeResponse = (
  nodes: HelpdeskMenuBaseItem[],
  nodeId: string,
  newResponse: string
): HelpdeskMenuBaseItem[] => {
  return nodes.map((node) => {
    if (node.id === nodeId) {
      return { ...node, response: newResponse };
    }
    if (node.children?.length) {
      return {
        ...node,
        children: updateNodeResponse(node.children, nodeId, newResponse),
      };
    }
    return node;
  });
};

/**
 * Searches through a tree structure of HelpdeskMenuBaseItem nodes and returns filtered results based on search criteria.
 *
 * The function implements the following search logic:
 * 1. If a parent node matches, it returns the parent with all its children
 * 2. If a child node matches, it returns the entire branch (parent nodes included)
 * 3. If no search text is provided, returns the original tree
 *
 * @param nodes - Array of HelpdeskMenuBaseItem nodes to search through
 * @param searchText - Text to search for in node descriptions and responses
 * @returns Filtered array of nodes that match the search criteria, maintaining tree structure
 *
 * @example
 * const tree = [
 *   { id: '1', description: 'Parent', children: [
 *     { id: '2', description: 'Child', response: 'Answer' }
 *   ]}
 * ];
 * const results = searchInTree(tree, 'answer');
 */
export const searchInTree = (nodes: HelpdeskMenuBaseItem[], searchText: string): HelpdeskMenuBaseItem[] => {
  if (!searchText) return nodes;

  const searchLower = deburr(searchText.toLowerCase());

  return nodes.reduce<HelpdeskMenuBaseItem[]>((filtered, node) => {
    const nodeCopy = { ...node };

    // Check if current node matches search criteria
    const matchesCurrentNode =
      deburr(nodeCopy.description?.toLowerCase() || "").includes(searchLower) ||
      deburr(nodeCopy.response?.toLowerCase() || "").includes(searchLower);

    // If current node matches and has children, include all children
    if (matchesCurrentNode && nodeCopy.children?.length) {
      filtered.push({
        ...nodeCopy,
        children: [...(node.children || [])],
      });
      return filtered;
    }

    // If node has children, search recursively
    if (nodeCopy.children?.length) {
      const matchingChildren = searchInTree(nodeCopy.children, searchText);
      if (matchingChildren.length > 0) {
        filtered.push({
          ...nodeCopy,
          children: matchingChildren,
        });
      }
    } else if (matchesCurrentNode) {
      // If leaf node matches, include it
      filtered.push(nodeCopy);
    }

    return filtered;
  }, []);
};

export const countResponses = (nodes: HelpdeskMenuBaseItem[]): number => {
  return nodes.reduce((count, node) => {
    if (!node.children?.length) {
      return count + 1;
    }
    return count + countResponses(node.children);
  }, 0);
};
